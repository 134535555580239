import { Page } from 'lemans-brandsites-common/components/Page';
import { SpinnerCss } from 'lemans-brandsites-common/components/SpinnerCss';
import { pageRestHandleSideEffects } from 'lemans-brandsites-common/libs/apiHandleSideEffects';
import * as React from 'react';
import { brandName } from 'theme/libs/config';
import { BannerImage } from '../BannerImage';
import { FourOhFour } from '../FourOhFour';
import './BikeListing.styles.less';
import { IBikeList, IBikeListingProps } from "./BikeListing.types";

export const BikeListing = (props:IBikeListingProps) => {
    const [displayFourOhFour, isLoading, data] = pageRestHandleSideEffects('bike-build-listing-page');
    const [bikeList, setBikeList] = React.useState<IBikeList[]>([]);

    React.useEffect(() => {
        setBikeList(data?.acf.bike_lists as IBikeList[])
    }, [data]);

    return (
        <>
            { displayFourOhFour && <FourOhFour />}
            { isLoading && <SpinnerCss />}
            { !isLoading && 
                <Page
                    className="Bike-builds"
                    description={`${brandName} - Bike Builds`}
                    robots={{ follow: true, index: true }}
                    title={`${brandName} - Bike Builds`}
                >
                    <BannerImage {...data?.acf} />
                    
                    <div className='bikeListBox'>
                        {bikeList && bikeList !== undefined && bikeList?.map((list: any, i) => 
                            <div key={list.id} className='bikeBox'>
                                <>
                                    <a href={list.bike_url} key={list.id}>
                                        <img src={`${list.bike_image}`} key={list.id}/> <br />
                                        <span className='title'>{`${list.bike_title}`}</span><br />
                                    </a>
                                </>
                            </div>
                        )}
                    </div>
                </Page>
            }
        </>
    );
}