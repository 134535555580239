import { asPage } from 'lemans-brandsites-common';
import { News } from 'lemans-brandsites-common/components/News/News.component';
import { INewsProps } from 'lemans-brandsites-common/components/News/News.types';
import { BikeDetails } from 'theme/components/BikeDetails';
import { BikeListing } from 'theme/components/BikeListing';
import { CatalogPage } from 'theme/components/CatalogPage';
import { Category } from 'theme/components/Category';
import { Contact } from 'theme/components/Contact';
import { DealerSearch } from 'theme/components/DealerSearch';
import { FourOhFour } from 'theme/components/FourOhFour';
import { HelpCenter } from 'theme/components/HelpCenter';
import { Homepage } from 'theme/components/Homepage';
import { InstructionSearch } from 'theme/components/InstructionSearch';
import { MMYComponent } from 'theme/components/MMY';
import { Part } from 'theme/components/Part';
import { Product } from 'theme/components/Product';
import { Search } from 'theme/components/Search';
import { SizeCharts } from 'theme/components/SizeCharts';
import { Sponsorship } from 'theme/components/Sponsorship';
import { StaticPage } from 'theme/components/StaticPage';
import { Team } from 'theme/components/Team';
import { productGroupUrl, prodUrl } from './util';

export const categoryUrl = (categoryId:number | string) => `/category/${categoryId}`;
export const partUrl     = (partNumber:string         ) => `/part/${partNumber.replace("-", "")}`;
export const routes = [
    {route: "/",                                      exact: true,  component: asPage<any>(Homepage)},
    {route: categoryUrl(":categoryId"),               exact: true,  component: asPage<any>(Category)},
    {route: prodUrl(":productId"),                    exact: true,  component: asPage<any>(Product)},
    {route: prodUrl(":productId", ":productGroupId"), exact: true,  component: asPage<any>(Product)},
    {route: productGroupUrl(":productGroupId"),       exact: true,  component: asPage<any>(Product)},
    {route: partUrl(":partNumber"),                   exact: true,  component: asPage<any>(Part)},
    {route: "/news",                                  exact: true,  component: News },
    {route: "/news/:slug",                            exact: true,  component: asPage<INewsProps>(News)},
    {route: "/search",                                exact: true,  component: asPage<any>(Search)},
    {route: "/contact",                               exact: true,  component: asPage<any>(Contact)},
    {route: "/catalog",                               exact: true,  component: asPage<any>(CatalogPage)},
    {route: "/dealer-search",                         exact: true,  component: asPage<any>(DealerSearch)},
    {route: "/mmy",                                   exact: true,  component: asPage<any>(MMYComponent)},
    {route: "/team/:type",                            exact: true,  component: asPage<any>(Team) },
    {route: "/bike-builds",                           exact: true,  component: asPage<any>(BikeListing) },
    {route: "/bike-builds/:slug",                     exact: true,  component: asPage<any>(BikeDetails) },
    {route: "/installation-instructions",             exact: true,  component: asPage<any>(InstructionSearch) },
    {route: "/size-charts",                           exact: true,  component: asPage<any>(SizeCharts)},
    {route: "/help-center",                           exact: true,  component: asPage<any>(HelpCenter)},
    {route: "/sponsorship",                           exact: true,  component: asPage<any>(Sponsorship)},
    {route: "/:slug",                                 exact: true,  component: asPage<any>(StaticPage)},
    {route: "",                                       exact: false, component: asPage<any>(FourOhFour)},
];