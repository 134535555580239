import { useCategoryNav } from 'lemans-brandsites-common/libs/ProductFeed/useFeed';
import * as React from 'react';
import { imageUrl } from 'theme/libs/util';
import './SizeCharts.styles.less';
import {ISizeChartsProps} from "./SizeCharts.types";

export const SizeCharts = (props:ISizeChartsProps) => {
    const nav = useCategoryNav();
    return <div className="sizeCharts">
        <h1>Size Charts</h1>
        {nav[0]?.catalogs[0].media
            .filter(m => m.tag.toLocaleUpperCase().includes("SIZE_CHART"))
            .map(media => <div key={media.mediaId}>
                <h2>{media.mediaDescription}</h2>
                <img src={imageUrl(media)}/>
            </div>)
        }
    </div>;
}
