import * as React from 'react';
import { api } from './api';

export const useCustomFields = <T extends {}>(slug:string):{data?:T, isLoading:boolean, fourOhFour:boolean} => {
    const [data, setData] = React.useState<T | undefined>();
    const [fourOhFour, setFourOhFour] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(true);

    React.useEffect(() => {
        setIsLoading(true);
        api.getPage(slug)
            .then(api.getCustomFields)
            .then(setData)
            .catch(() => {setFourOhFour(true)})
            .finally(() => {setIsLoading(false);});
    }, [slug]);
    
    return {data, isLoading, fourOhFour};
}