import { Button, Checkbox, Col, Form, Input, Row, Select } from 'antd';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { api } from 'lemans-brandsites-common/libs/api';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import { isHumanUser, useReCaptcha } from 'lemans-brandsites-common/libs/ReCaptcha';
import * as React from "react";
import { domain, newsletterKey } from 'theme/libs/config';
import logo from '../../assets/images/moose_logo.png';
import './NewsLetterSignUp.styles.less';
import { NewsLetterProps } from "./NewsLetterSignUp.types";

export const NewsLetterSignUp = (props:NewsLetterProps) => {
    const siteKey = '6Lc5Lp8UAAAAAFsvjfBy_h2YNczjXqtyBswUj9bT';
    // const [newsletterGraphics, setNewsletterGraphics] = React.useState({} as INewsletterGraphics);
    const [message, setMessage] = React.useState('');
    const {isLoading, setIsLoading} = useReCaptcha(siteKey);
    const [form] = Form.useForm();

    // set localstorage variables with the chosen machine/category type and the time of selection
    // const handleImgClick = () => () => {
    //     localStorage.setItem('hasVisited', 'yes');
    //     // re-render the page
    //     props.setIsLoading(true);
    //     props.setRefreshPage(true);
    // }



     const handleSubmit = (vals:any) => {
        // e.preventDefault();
        console.log(vals)

        form.validateFields().then(async (values) => {
            const isValidUser = await validateUser();

            if (!isValidUser) {
                localStorage.setItem('hasVisited', 'failed');
                handleSubmissionError();
            } else {
                // sanitizeAndFormatValues(values);
                submitToCorrespondence(values);
            }
        })
        .catch((errorInfo) => {
            console.log(errorInfo);
            return // Fails validation
        })
    };

    const validateUser = async() => {
        let output;
        setIsLoading(true);
        try {
            output = await isHumanUser(siteKey, 'contactUs', apiConfig);
        }
        catch {
            output = false;
        }
        setIsLoading(false);

        return output;
    }

    const submitToCorrespondence = async (values: any) => {
        const url = `lemans/rest/domain/${domain}/form/${newsletterKey}/submit`;
        setIsLoading(true);

        try {
            const response = await api.post(url, values, 'form');

            if (response.statusCode === 200) {
                setMessage(`<h2>THANK YOU!</h2><br />
                    <p>We are happy you have signed up to get all the latest from Moose Racing, including race news, rider profiles and new products.</p>`
                );
                setTimeout(() => { 
                    localStorage.setItem('hasVisited', 'yes');
                }, 3000);
                
            } else {
                localStorage.setItem('hasVisited', 'failed');
                handleSubmissionError();
            }
        }
        catch (err) {
            localStorage.setItem('hasVisited', 'failed');
            handleSubmissionError();
        }
        finally {
            setIsLoading(false);
        }
    }

    const handleSubmissionError = () => setMessage(
        `<h2>Server Error</h2>
        <p>We're sorry, there was a server error. Please try again later.</p>`
    );
            
    /* eslint-disable no-template-curly-in-string */
    const validateMessages = {
        required: '${label} is required!',
        types: {
        email: '${label} is not a valid email!',
        },
    };
    /* eslint-enable no-template-curly-in-string */

    const selectFilter = (input:string, option:any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    return (
        <div className="newsletter-form-wrapper--div"> 
        <Spinner isLoading={isLoading}>
            { message && 
            <>
                <div 
                    className={'contact-form-message--div'}
                    dangerouslySetInnerHTML={{__html: message}} 
                    // style={divStyle}
                />
                <Row>
                    <Col xs={24}>
                        <div className='mooseLogo'> <img alt="Moose Racing Logo" src={logo}/></div>
                    </Col>
                </Row>
            </>
            } 
            {!message &&         
            <div id="newsLetterContainer">
                {/* <span className="closeBtn" onClick={handleImgClick()}>X</span>
                */}
                <>
                    <div className="newsletter-form-container--div">
                        <Row>
                            <Col xs={24}>
                                <>
                                    <h1>KEEP UP TO DATE!</h1>
                                    <div className='newsletter_descr'>
                                        get the latest from moose racing including new racewear, hard parts and exclusive racing and rider content.
                                    </div>
                                </>
                            </Col>
                        </Row>
                        <Form 
                            onFinish={handleSubmit} form={form} validateMessages={validateMessages} className={'contact-form-wrapper--form'} layout={"horizontal"}>
                            <Row gutter={16}>
                                <Col xs={24} lg={12}>
                                    <Form.Item name='form_1_firstName' rules={[{ required: true }]}>
                                        <Input id={'first-name'} maxLength={30} placeholder="FIRST NAME" />
                                    </Form.Item>
                                </Col>
                                <Col xs={24} lg={12}>
                                    <Form.Item name='form_2_lastName'>
                                        <Input id={'last-name'} maxLength={30} placeholder="LAST NAME"/>
                                    </Form.Item>
                                </Col>
                            </Row>

                            <Row gutter={16}>
                                <Col xs={24} lg={12}>
                                    <Form.Item name='form_3_emailAddress' rules={[{ required: true }]}>
                                        <Input type={'email'} id={'email'} placeholder="EMAIL"/>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                                <Col xs={12} lg={6}>
                                    <Form.Item name='form_4_bikeOwner' valuePropName="checked">
                                        <Checkbox>I Own a Bike</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item name='form_5_bikeBrand'>
                                        <Select
                                            id={'bike-brand'}
                                            showSearch={true}
                                            filterOption={selectFilter}
                                            placeholder={'Brand'}
                                        >                                              
                                            <Select.Option value="BETA" key="bike-BETA">BETA</Select.Option>
                                            <Select.Option value="BMW" key="bike-BMW">BMW</Select.Option>
                                            <Select.Option value="Ducati" key="bike-Ducati">Ducati</Select.Option>
                                            <Select.Option value="Gas Gas" key="bike-gasgas">Gas Gas</Select.Option>
                                            <Select.Option value="Honda" key="bike-Honda">Honda</Select.Option>
                                            <Select.Option value="Husqvarna" key="bike-Husqvarna">Husqvarna</Select.Option>
                                            <Select.Option value="Kawasaki" key="bike-Kawasaki">Kawasaki</Select.Option>
                                            <Select.Option value="Other" key="bike-Other">Other</Select.Option>
                                            <Select.Option value="Sherco" key="bike-Sherco">Sherco</Select.Option>
                                            <Select.Option value="Suzuki" key="bike-Suzuki">Suzuki</Select.Option>
                                            <Select.Option value="Triumph" key="bike-Triumph">Triumph</Select.Option>
                                            <Select.Option value="Yamaha" key="bike-Yamaha">Yamaha</Select.Option>
                                        </Select>
                                    {/* )} */}
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item name='form_6_atvOwner' valuePropName="checked">
                                        <Checkbox >I Own an ATV</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item name='form_7_atvBrand'>
                                        <Select
                                            id={'atv-brand'}
                                            showSearch={true}
                                            filterOption={selectFilter}
                                            placeholder={'Brand'}
                                        >
                                            <Select.Option value="BETA" key="atv-BETA">BETA</Select.Option>
                                            <Select.Option value="BMW" key="atv-BMW">BMW</Select.Option>
                                            <Select.Option value="Ducati" key="atv-Ducati">Ducati</Select.Option>
                                            <Select.Option value="Gas Gas" key="atv-gasgas">Gas Gas</Select.Option>
                                            <Select.Option value="Honda" key="atv-Honda">Honda</Select.Option>
                                            <Select.Option value="Husqvarna" key="atv-Husqvarna">Husqvarna</Select.Option>
                                            <Select.Option value="Kawasaki" key="atv-Kawasaki">Kawasaki</Select.Option>
                                            <Select.Option value="Other" key="atv-Other">Other</Select.Option>
                                            <Select.Option value="Sherco" key="atv-Sherco">Sherco</Select.Option>
                                            <Select.Option value="Suzuki" key="atv-Suzuki">Suzuki</Select.Option>
                                            <Select.Option value="Triumph" key="atv-Triumph">Triumph</Select.Option>
                                            <Select.Option value="Yamaha" key="atv-Yamaha">Yamaha</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </Row>
                            <Row gutter={16}>
                            <Col xs={12} lg={6}>
                                    <Form.Item name='form_8_sportUtvOwner' valuePropName="checked">
                                        <Checkbox >I Own a sport UTV</Checkbox>
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={6}>
                                    <Form.Item name='form_9_sportUtvBrand'>
                                        <Select
                                            id={'utv-brand'}
                                            showSearch={true}
                                            filterOption={selectFilter}
                                            placeholder={'Brand'}
                                        >
                                            <Select.Option value="Arctic Cat" key="utv-arcticcat">Arctic Cat</Select.Option>
                                            <Select.Option value="Can-Am" key="utv-canam">Can-Am</Select.Option>
                                            <Select.Option value="CF Moto" key="utv-cfmoto">CF Moto</Select.Option>
                                            <Select.Option value="HiSun" key="utv-hisun">HiSun</Select.Option>
                                            <Select.Option value="Honda" key="utv-honda">Honda</Select.Option>
                                            <Select.Option value="Kawasaki" key="utv-kawasaki">Kawasaki</Select.Option>
                                            <Select.Option value="Polaris" key="utv-polaris">Polaris</Select.Option>
                                            <Select.Option value="Yamaha" key="utv-yamaha">Yamaha</Select.Option>
                                            <Select.Option value="Kymco" key="utv-kymco">Kymco</Select.Option>
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col xs={12} lg={3}>
                                    <Form.Item>
                                        <Button className={'newsLetterSubmit'} type={'primary'} htmlType={'submit'}>Submit</Button>
                                    </Form.Item>                                        
                                </Col>
                            </Row>
                            <Row>
                            <Col xs={24}>
                                <div className="captchaText">
                                    <small>
                                        This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy 
                                        Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                                    </small>
                                </div>
                            </Col>
                            </Row>
                        </Form>
                    </div>
                </>
            </div> 
            }        
        </Spinner>
        </div>
    );
}