import { BackTop, Layout } from "antd";
import * as React from 'react';
import { useEffect } from "react";
import ReactGA from "react-ga4";
import { BrowserRouter } from "react-router-dom";
import { gaMeasurementId } from 'theme/libs/config';
import { Content } from '../Content';
import { Footer } from '../Footer';
import { Header } from '../Header';
import './App.styles.less';
import { IAppProps } from "./App.types";

export const App = (props: IAppProps) => {
    useEffect(() => {
        // const history = createBrowserHistory();
        // googleAnalytics.init(history, gAnalyticsId);
        ReactGA.initialize(gaMeasurementId);
    }, []);

    return <div className="App">
        <BrowserRouter>
            <Layout>
                <BackTop />
                <Header />
                <Content />
                <Footer />
            </Layout>
        </BrowserRouter>
    </div>;
}