import './RelatedEntityList.styles.less';

import {
    IFeedProductRelatedEntity
} from 'lemans-brandsites-common/libs/ProductFeed/masterFeed.types';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { imageUrl, productGroupUrl, prodUrl } from 'theme/libs/util';
import { switchOn } from 'ts-functional';

import { IRelatedEntityListProps } from './RelatedEntityList.types';

const getUrl = (entity:IFeedProductRelatedEntity):string => switchOn(entity.relatedClass, {
    // Product: () => prodUrl(parseInt(entity.relatedEntityId, 10)),
    Product: () => prodUrl(entity.relatedId),
    ProductGroup: () => productGroupUrl(entity.relatedId),
    default: () => "#",
}) || "";


export const RelatedEntityList = ({title, entities}:IRelatedEntityListProps) => !!entities && entities.length > 0 ? 
    <div className="relatedEntities">
        <h2>{title || "Related Items"}</h2>
        {(entities || []).map(entity => {
        return <Link to={getUrl(entity)} key={entity.relatedEntityId}>
            <img src={imageUrl(entity, {x: 256, y: 256})} />
            {entity.relatedName}
        </Link>
        }
    )}
    </div>
    : null;
