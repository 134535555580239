import * as React from 'react';

export const useToggle = (defaultVal: boolean = false) => {
    const [isOn, setIsOn] = React.useState(defaultVal);

    const on     = () => {setIsOn(true         );}
    const off    = () => {setIsOn(false        );}
    const toggle = () => {setIsOn(old => !old  );}

    return {isOn, on, off, toggle};
}