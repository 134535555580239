import * as React from 'react';
import { Route, Switch } from 'react-router';
import { routes } from 'theme/libs/routes';
import { IRoutesProps } from "./Routes.types";

export const Routes = (props:IRoutesProps) =>
    <Switch>
        {routes.map((route: any) =>
            <Route exact={route.exact || false} key={route.route || '404'} path={route.route} component={route.component} />
        )}
    </Switch>;
