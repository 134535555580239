import { StaticPageContent } from 'lemans-brandsites-common/components/StaticPage/StaticPageContent/StaticPageContent.component';
import { StaticPageVideo } from 'lemans-brandsites-common/components/StaticPage/StaticPageVideo/StaticPageVideo.component';
import { useCustomFields } from 'lemans-brandsites-common/libs/useCustomFields';
import * as React from 'react';
import { BannerImage } from '../BannerImage';
import './StaticPage.styles.less';
import { IStaticPageProps } from "./StaticPage.types";

export const StaticPage = ({slug}:IStaticPageProps) => {
    const {data} = useCustomFields<{banner_image: string, banner_image_mobile: string;}>(slug);
    
    return <div className="staticPage">
        <BannerImage {...data} />
        <StaticPageVideo slug={slug} />
        <StaticPageContent slug={slug} />
    </div>;
}