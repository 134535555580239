import { Spin } from 'antd';
import { IFeedProduct, IFeedProductGroup, IProductSemantic } from 'lemans-brandsites-common/libs/ProductFeed/masterFeed.types';
import { useCategoryNav } from 'lemans-brandsites-common/libs/ProductFeed/useFeed';
import { useProductFeed } from 'lemans-brandsites-common/libs/ProductFeed/useProductFeed';
import * as React from 'react';
import { useHistory } from 'react-router';
import { prodUrl } from 'theme/libs/util';
import { getTag, getTemplate } from './Product.helpers';
import './Product.styles.less';
import { IProductProps } from "./Product.types";

export const Product = ({productId, productGroupId}: IProductProps) => {
    const prodGroupId = !!productId ? undefined : productGroupId;

    const [data, loading] = useProductFeed<IProductSemantic, {}>({productId, productGroupId: prodGroupId}, 1 );
    const [nav, navLoading] = useCategoryNav();

    const product:IFeedProduct | null = !!data && data.products ? data?.products[0] as IFeedProduct : null;

    const history = useHistory();

    // replace the /productGroup/$productGroupId url with the full product/$productId/productGroup/$productGroupId url
    // so that the masterfeed will be called with product id instead of productgroup id, and will always have all the 
    // info we need - this will specially help with some hardparts which has the "Related Products" section right under the product 
    // description, which will show that section only when calling the masterfeed with productId. 
    // Eg: https://www.mooseracing.com/product/672300/productGroup/489722
    if(productId === undefined && !!productGroupId && !!product?.productId) {
        history.replace(prodUrl(product?.productId, productGroupId));
    }

    const tag = getTag(
        product?.tag || "",
        nav?.catalogs[0].categories || [],
        product?.breadcrumb || []
    );

    let productGroup:IFeedProductGroup | undefined;
    if(product) {
        productGroup = !!productGroupId
            ? product.productGroups?.filter(g => `${g.productGroupId}` === `${productGroupId}`)[0]
            : (product.productGroups || [])[0];
    }

    const Template = getTemplate(tag);
    
    return <Spin spinning={loading || navLoading}>
        {!!product ?
        <div className="product">
            {!!data && !!nav && <Template product={data.products[0]} productGroup={productGroup as IFeedProductGroup}/>}
        </div>
        : null
        }
        </Spin> 
}