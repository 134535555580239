import * as React from 'react';

import { Col, Modal, Row } from 'antd';

import { Page } from 'lemans-brandsites-common/components/Page';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import { brandName, devMapsKey, prodMapsKey } from 'theme/libs/config';

import { DealerLocatorInputGroup } from 'lemans-brandsites-common/components/DealerLocatorInputGroup';
import { DealerLocatorInputParams } from 'lemans-brandsites-common/components/DealerLocatorInputGroup/DealerLocatorInputGroup.types';
import { DealerLocatorMap } from 'lemans-brandsites-common/components/DealerLocatorMap';
import { mapTheme } from 'lemans-brandsites-common/components/DealerLocatorMap/DealerLocatorMap.types';
import { SpinnerCss } from 'lemans-brandsites-common/components/SpinnerCss';
import { dealer } from 'lemans-brandsites-common/libs/DealerLocatorApi/libs/Dealer';
import { IDealerData } from 'lemans-brandsites-common/libs/DealerLocatorApi/libs/OnlineDealer/onlineDealer.types';
import { DealerListItem } from '../DealerListItem';
import { FourOhFour } from '../FourOhFour';
import { OnlineDealers } from '../OnlineDealers';
import { IDealerSearchProps } from './DealerSearch.types';

import './DealerSearch.styles.less';

export const DealerSearch = (props:IDealerSearchProps) => {
    // export const DealerSearch = (props:IDealerSearchProps) => <BaseComponent {...props} />;
    const [displayFourOhFour, setDisplayFourOhFour] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);
    const [isOnlineDealersVisible, setIsOnlineDealersVisible] = React.useState(false);
    const [state, setState] = React.useState({
        activeDealerList: undefined as IDealerData[] | undefined,
        dealerList: undefined as IDealerData[] | undefined,
        offPageDealerList: undefined as IDealerData[] | undefined,
        selectedDealerIndex: undefined as number | undefined
    });
    const [infoMessage, setInfoMessage] = React.useState('');

    // const history = useHistory();

    const googleMapsKey = process.env.NODE_ENV === 'production'
        ? prodMapsKey
        : devMapsKey;

    const onItemClick = (index: number) => {
        setState({ ...state, selectedDealerIndex: index });
    }

    const onSearch = async (params: DealerLocatorInputParams) => {
        setIsLoading(true);
        setInfoMessage('');
        setState({
            ...state,
            activeDealerList: undefined,
            dealerList: undefined,
            offPageDealerList: undefined,
            selectedDealerIndex: undefined
        });

        try {
            const data = await dealer(apiConfig, params);

            if (!data) {
                setDisplayFourOhFour(true);
            }

            if (!Array.isArray(data)) {
                throw new Error('An error was returned from the Dealer Locator Service.');
            }

            data.length === 0 ? setInfoMessage('Sorry, no dealers were found using that criteria, please try again.') : setInfoMessage('');

            const activeDealerList = data.length > 5 ? data.slice(0, 5) : data;
            const offPageDealerList = data.length > 5 ? data.slice(5) : undefined;

            setState({
                ...state,
                activeDealerList,
                dealerList: data,
                offPageDealerList,
                selectedDealerIndex: undefined
            });
        }
        catch {
            setDisplayFourOhFour(true);
        }
        finally {
            setIsLoading(false);
        }
    }

    // const showOnlineDealers = () => history.push('/online-dealers');
    const showOnlineDealers = () => setIsOnlineDealersVisible(true);
    const onWebDelearModalClose = () => setIsOnlineDealersVisible(false);

    return (
        <>
            {displayFourOhFour && <FourOhFour />}
            {!!state &&
                <Page className="DealerSearch" title={`${brandName} - Dealers`}>
                    <Row>
                        <Col xs={{ span: 24, order: 1 }} lg={{ span: 12, order: 2 }}>
                            {isLoading && !state.dealerList && <SpinnerCss />}
                            {!isLoading &&
                                <div className="dl-form-container">
                                    <h1>Find a Dealer</h1>
                                    <Modal className={"onlineDealerModal"} destroyOnClose width={'100%'} footer={false} open={isOnlineDealersVisible} onCancel={onWebDelearModalClose}>
                                        <OnlineDealers />
                                    </Modal>
                                    <DealerLocatorInputGroup
                                        apiConfig={apiConfig}
                                        onOnlineDealerClick={showOnlineDealers}
                                        onSearch={onSearch}
                                    />
                                    <div className={'dealer-locator-results--div'}>
                                        <p>{infoMessage}</p>
                                        {state.dealerList && state.dealerList.map((item, index) =>
                                            <DealerListItem
                                                dealerData={item}
                                                index={index}
                                                key={index}
                                                onClick={onItemClick}
                                            />
                                        )
                                        }
                                    </div>
                                </div>
                            }
                        </Col>
                        <Col className="map" xs={{ span: 24, order: 2 }} lg={{ span: 12, order: 1 }}>
                            <DealerLocatorMap
                                apiKey={googleMapsKey}
                                dealerList={state.dealerList}
                                featuredMarkerDir={'//assets-static.lemansnet.com/common/img/dealerLocator/ams_markers/'}
                                featuredMarkerImageName={'dlr_locator_feature.png'}
                                selectedDealerIndex={state.selectedDealerIndex}
                                theme={mapTheme.Dark}
                            />
                        </Col>
                    </Row>
                </Page>
            }
        </>
    )
}