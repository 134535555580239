import { SearchResults as BaseComponent } from "lemans-brandsites-common/components/SearchResults/SearchResults.component";
import * as React from 'react';
import './SearchResults.styles.less';
import { ISearchResultsProps } from "./SearchResults.types";

// export const getProductGroupUrl = (productId: number, productGroupId: number) => {
//     return `/product/${productGroupId}`;
// }
export const getProductGroupUrl = (productId: number, productGroupId: number) => !!productGroupId
    ? `/product/${productId}/productGroup/${productGroupId}`
    : `/product/${productId}`;

export const SearchResults = (props:ISearchResultsProps) => <BaseComponent {...props} url={getProductGroupUrl} />;
