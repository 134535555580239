import { Checkbox, Col, Form, Input, Row, Select, Spin } from 'antd';
import { useForm } from 'antd/es/form/Form';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { StyledButton } from 'lemans-brandsites-common/components/StyledButton';
import { api } from 'lemans-brandsites-common/libs/api';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import { sanitizeAndFormat } from 'lemans-brandsites-common/libs/formHelpers';
import { isHumanUser, useReCaptcha } from 'lemans-brandsites-common/libs/ReCaptcha';
import * as React from "react";
import { contactFormKey, domain } from 'theme/libs/config';
import './HelpCenterForm.styles.less';
import { IHelpCenterFormProps, IHelpCenterFormState } from './HelpCenterForm.types';

export const HelpCenterForm = (props:IHelpCenterFormProps) => {
    const [form] = useForm();

    const siteKey = '6Lc5Lp8UAAAAAFsvjfBy_h2YNczjXqtyBswUj9bT';
    const {isLoading, setIsLoading} = useReCaptcha(siteKey);
    const [state, setState] = React.useState<IHelpCenterFormState>({
        fetching: true,
        makeList: undefined,
        message: undefined
    });

    const handleSubmit = async (values:any) => {

        const isValidUser = await validateUser();

        if (!isValidUser) {
            handleSubmissionError();
        } else {
            sanitizeAndFormatValues(values);
            submitToCorrespondence(values);
        }
    };

    const sanitizeAndFormatValues = (values:any) => {
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                values[key] = sanitizeAndFormat(key, values[key]);
            }
        }
    }

    const validateUser = async() => {
        let output;
        setIsLoading(true);
        try {
            output = await isHumanUser(siteKey, 'contactUs', apiConfig);
        }
        catch {
            output = false;
        }
        setIsLoading(false);

        return output;
    }

    const submitToCorrespondence = async (values: any) => {
        const url = `lemans/rest/domain/${domain}/form/${contactFormKey}/submit`;
        setIsLoading(true);

        try {
            const response = await api.post(url, values, 'form');

            if (response.statusCode === 200) {
                setState({
                    ...state,
                    message: `<h2>Your Message Has Been Sent</h2>
                    <p>Thank you for contacting us. We have received your submission and will respond as soon as possible.</p>`
                });
            } else {
                handleSubmissionError();
            }
        }
        catch (err) {
            handleSubmissionError();
        }
        finally {
            setIsLoading(false);
        }
    }

    const handleSubmissionError = () => setState({...state, message:
        `<h2>Server Error</h2>
        <p>We're sorry, there was a server error. Please try again later.</p>`
    });

    const selectFilter = (input:string, option:any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    return (
    <Spinner isLoading={isLoading}>
        <div id="helpCenterContainer--wrapper">
        { state.message && 
            <div 
                className={'contact-form-message--div'}
                dangerouslySetInnerHTML={{__html: state.message}} 
            />
        }
        { !state.message &&
            <Form form={form} onFinish={handleSubmit} className={'contact-form-wrapper--form'}>
                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item label='First Name' name='form_1_firstName' required>
                            <Input id={'first-name'} maxLength={30} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Last Name' required name='form_1_lastName'>
                            <Input id={'last-name'} maxLength={30} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={18}>
                        <Form.Item label='Email Address' required name='form_1_emailAddress'>
                            <Input type={'email'} id={'email'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={6}>
                        <Form.Item label='Zip Code' name='form_1_zip'>
                            <Input id={'zip'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24}>
                        <Form.Item label='Comment' required name='form_1_comments'>
                            <Input.TextArea rows={5} id={'comments'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24}>
                        <Form.Item name='form_1_emailSignUp' valuePropName="checked"> 
                            <Checkbox >Sign Me Up For Moose Racing Emails</Checkbox>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xl={6} xs={24} sm={12}>
                        <Form.Item name='form_5_bikeOwner' valuePropName="checked">
                            <Checkbox >I Own a Bike</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xl={6} xs={24} sm={12}>
                        <Form.Item name='form_5_bikeBrand'>
                            <Select
                                id={'bike-brand'}
                                showSearch={true}
                                filterOption={selectFilter}
                                placeholder={'Brand'}
                                notFoundContent={state.fetching ? <><Spin size="small" /><span style={{marginLeft: '5px'}}>Loading ...</span></> : undefined}
                            >
                                <Select.Option value="BETA" key="bike-BETA">BETA</Select.Option>
                                <Select.Option value="BMW" key="bike-BMW">BMW</Select.Option>
                                <Select.Option value="Ducati" key="bike-Ducati">Ducati</Select.Option>
                                <Select.Option value="Gas Gas" key="bike-gasgas">Gas Gas</Select.Option>
                                <Select.Option value="Honda" key="bike-Honda">Honda</Select.Option>
                                <Select.Option value="Husqvarna" key="bike-Husqvarna">Husqvarna</Select.Option>
                                <Select.Option value="Kawasaki" key="bike-Kawasaki">Kawasaki</Select.Option>
                                <Select.Option value="Other" key="bike-Other">Other</Select.Option>
                                <Select.Option value="Sherco" key="bike-Sherco">Sherco</Select.Option>
                                <Select.Option value="Suzuki" key="bike-Suzuki">Suzuki</Select.Option>
                                <Select.Option value="Triumph" key="bike-Triumph">Triumph</Select.Option>
                                <Select.Option value="Yamaha" key="bike-Yamaha">Yamaha</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xl={6} xs={24} sm={12}>
                        <Form.Item name='form_6_atvOwner' valuePropName="checked">
                            <Checkbox >I Own an ATV</Checkbox>
                        </Form.Item>
                    </Col>
                    <Col xl={6} xs={24} sm={12}>
                        <Form.Item name='form_7_atvBrand'>
                            <Select
                                id={'atv-brand'}
                                showSearch={true}
                                filterOption={selectFilter}
                                placeholder={'Brand'}
                                notFoundContent={state.fetching ? <><Spin size="small" /><span style={{marginLeft: '5px'}}>Loading ...</span></> : undefined}
                            >
                                <Select.Option value="BETA" key="atv-BETA">BETA</Select.Option>
                                <Select.Option value="BMW" key="atv-BMW">BMW</Select.Option>
                                <Select.Option value="Ducati" key="atv-Ducati">Ducati</Select.Option>
                                <Select.Option value="Gas Gas" key="atv-gasgas">Gas Gas</Select.Option>
                                <Select.Option value="Honda" key="atv-Honda">Honda</Select.Option>
                                <Select.Option value="Husqvarna" key="atv-Husqvarna">Husqvarna</Select.Option>
                                <Select.Option value="Kawasaki" key="atv-Kawasaki">Kawasaki</Select.Option>
                                <Select.Option value="Other" key="atv-Other">Other</Select.Option>
                                <Select.Option value="Sherco" key="atv-Sherco">Sherco</Select.Option>
                                <Select.Option value="Suzuki" key="atv-Suzuki">Suzuki</Select.Option>
                                <Select.Option value="Triumph" key="atv-Triumph">Triumph</Select.Option>
                                <Select.Option value="Yamaha" key="atv-Yamaha">Yamaha</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col xs={24}>
                        <Form.Item>
                            <StyledButton type={'primary'} htmlType={'submit'}>Submit</StyledButton>
                        </Form.Item>
                        <div>
                            <small>
                                This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy 
                                Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                            </small>
                        </div>
                    </Col>
                </Row>
            </Form>
        }
        </div>
        </Spinner>
    );
}