import { Spin } from 'antd';
import * as React from "react";
import { spinnerLogo } from "theme/libs/config";
import './Spinner.styles.less';
import { ISpinnerProps } from "./Spinner.types";

export const Spinner = (props: ISpinnerProps) => <div className="spinner">
    <Spin
        spinning={props.isLoading === undefined ? true : props.isLoading}
        delay={50}
        className={props.children ? 'spinner-wrapper-children--spin' : 'spinner-wrapper--spin'}
        indicator={!props.defaultIndicator ? <img src={spinnerLogo} className={'spinner--img'} /> : undefined}
        children={props.children}
        size={props.size}
    />
</div>
