import * as React from 'react';
import { Link } from 'react-router-dom';
import { imageUrl, prodUrl } from 'theme/libs/util';
import './ProductGroupLinks.styles.less';
import { IProductGroupLinksProps } from "./ProductGroupLinks.types";

export const ProductGroupLinks = ({title, groups, currentGroupId}:IProductGroupLinksProps) => groups.length > 1 ? <>
    <p className="productGroupLinkTitle">{title}</p>
    <div className="productGroupLinks">
        {groups.filter(g => g.productGroupId !== currentGroupId).map(group => <div key={group.productGroupId} className="productGroupLink">
            <Link to={prodUrl(group.productId, group.productGroupId)}>
                <img src={imageUrl(group, {x: 256, y: 256})} /><br/>
                {group.productGroupName}
            </Link>
        </div>)}
    </div>
</> : null;
