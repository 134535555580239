import { memoizePromise } from 'ts-functional';
import { api } from '../api';
import { IPage } from '../api.types';
import { IResponseData } from './page.types';


export const page = async (slug:string):Promise<IPage> => {
    const res = await getPage(slug);

    return res.body[0];
} 

const getPage = memoizePromise(
    (slug:string):Promise<IResponseData> => api.get(`wp/v2/pages`).query({slug})
);