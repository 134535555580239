
import { prodHostUrl, readyHostUrl } from 'theme/libs/config';
import { Environment } from "./getEnvironment.types";

export const getEnvironment = (hostName:string):Environment => {
    // Valid environments
    if(hostName === 'localhost') {return {env: "dev", number: null, locale: null};}
    // if(hostName === stageHostUrl) {return {env:"stage", number: null, locale: null};}
    if(hostName.indexOf("stage")) {return {env:"stage", number: null, locale: null};}
    if(hostName === prodHostUrl) {return {env: "prod", number: null, locale: null};}
    if(hostName === readyHostUrl) {return {env: "ready", number: null, locale: null};} 

    // Invalid environments
    return {env: null, number: null, locale: null};
}
