import Icon from '@ant-design/icons/lib/components/Icon';
import { Col, Form, FormItemProps, Input, Row, Select } from 'antd';
import { StateConfig } from 'lemans-brandsites-common/components/DealerLocatorInputGroup/StateSelect.config';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { StyledButton } from 'lemans-brandsites-common/components/StyledButton';
import { api } from 'lemans-brandsites-common/libs/api';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import { isHumanUser, useReCaptcha } from 'lemans-brandsites-common/libs/ReCaptcha';
import * as React from 'react';
import { sanitizeAndFormat } from './SponsorshipForm.helpers';
import './SponsorshipForm.styles.less';
import { ISponsorshipFormProps } from "./SponsorshipForm.types";

export const SponsorshipForm = (props:ISponsorshipFormProps) => {
    const siteKey = '6Lc5Lp8UAAAAAFsvjfBy_h2YNczjXqtyBswUj9bT';
    const {isLoading, setIsLoading} = useReCaptcha(siteKey);
    const [message, setMessage] = React.useState<string|undefined>(undefined);

    const handleSubmit = async (values:any) => {
        const isValidUser = await validateUser();

        if (!isValidUser) {
            handleSubmissionError();
        } else {
            sanitizeAndFormatValues(values);
            submitToCorrespondence(values);
        }
    
        // if (props.onSubmit) {
        //     props.onSubmit();
        // }
    };

    const sanitizeAndFormatValues = (values:any) => {
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                values[key] = sanitizeAndFormat(key, values[key]);
            }
        }
    }

    const validateUser = async() => {
        let output;
        setIsLoading(true);
        try {
            output = await isHumanUser(siteKey, 'sponsorship', apiConfig);
        }
        catch {
            output = false;
        }
        setIsLoading(false);

        return output;
    }

    const submitToCorrespondence = async (values: any) => {
        const url = 'lemans/rest/domain/5/form/moose_sponsorship/submit';
        setIsLoading(true);

        try {
            const response = await api.post(url, values, 'form');

            if (response.statusCode === 200) {
                setMessage(
                    `<h2>Your Message Has Been Sent</h2>
                    <p>Thank you for contacting us. We have received your submission and will respond as soon as possible.</p>`
                );
            } else {
                handleSubmissionError();
            }
        }
        catch (err) {
            handleSubmissionError();
        }
        finally {
            setIsLoading(false);
        }
    }

    const handleSubmissionError = () => setMessage(
        `<h2>Server Error</h2>
        <p>We're sorry, there was a server error. Please try again later.</p>`
    );

    const selectFilter = (input:string, option:any) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0;

    const validationConfig:Omit<Partial<FormItemProps>, 'rules'> & Required<Pick<FormItemProps, 'rules'>> = {
        rules: [{ 
            message: 'Required field',
            required: true, 
            type: 'string', 
        }],
        validateTrigger: ['onChange', 'onBlur']
    };

    const emailValidationConfig:Partial<FormItemProps> = {
        rules: [...validationConfig.rules, {
            message: 'Not a valid email address',
            type: 'email',
        }],
        validateTrigger: validationConfig.validateTrigger
    };

    const phoneRequiredValidationConfig:Partial<FormItemProps> = {
        rules: [...validationConfig.rules, {
            message: 'Use xxx-xxx-xxxx format',
            pattern: new RegExp('^[0-9]{3}-[0-9]{3}-[0-9]{4}$'),
        }],
        validateTrigger: validationConfig.validateTrigger
    };

    const phoneValidationConfig:Partial<FormItemProps> = {
        rules: [{
            message: 'Use xxx-xxx-xxxx format',
            pattern: new RegExp('^[0-9]{3}-[0-9]{3}-[0-9]{4}$'),
        }],
        validateTrigger: ['onChange', 'onBlur']
    };

    return (
        <Spinner isLoading={isLoading}>
        { message && 
            <div 
                className={'sponsorship-form-message--div'}
                dangerouslySetInnerHTML={{__html: message}} 
            />
        }
        { !message &&
            <Form onFinish={handleSubmit} className={'sponsorship-form-wrapper--form'}>
                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item label='First Name' {...validationConfig}  name='form_1_FirstName'>
                             <Input id={'first-name'} maxLength={30} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Last Name' {...validationConfig}  name='form_2_LastName'>
                            <Input id={'last-name'} maxLength={30} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Birthday' {...validationConfig}  name='form_3_BirthDate'>
                            <Input type={'date'} id={'birthday'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Email Address' {...emailValidationConfig}  name='form_4_emailAddress'>
                            <Input type={'email'} id={'email'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Phone Number' {...phoneRequiredValidationConfig}  name='form_5_PhoneNumber'>
                            <Input type={'phone'} id={'phone'} placeholder="xxx-xxx-xxxx"/>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Address' {...validationConfig}  name='form_6_address'>
                            <Input id={'address'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col lg={6} xs={12}>
                        <Form.Item label='City' {...validationConfig}  name='form_7_city'>
                            <Input id={'city'} />
                        </Form.Item>
                    </Col>
                    <Col lg={6} xs={12}>
                        <Form.Item label='Zip Code' {...validationConfig}  name='form_8_zip'>
                            <Input id={'zip'} />
                        </Form.Item>
                    </Col>
                    <Col lg={6} xs={12}>
                        <Form.Item label='State' {...validationConfig}  name='form_9_state'>
                            <Select 
                                id={'state'}
                                showSearch={true}
                                filterOption={selectFilter}
                            >
                            { StateConfig.map( item => 
                                <Select.Option key={item.id} value={item.id}>{item.text}</Select.Option>    
                            )}
                            </Select>
                        </Form.Item>
                    </Col>
                </Row>
                <hr />
                <h4>Series and Class You Race In (Latest 3)</h4>
                <Row gutter={16}>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Series' {...validationConfig} name='form_10_Series1'>
                            <Input id={'series1'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Class' {...validationConfig} name='form_11_Class1'>
                            <Input id={'class1'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Current Standings' {...validationConfig} name='form_12_CurrentStanding1'>
                            <Input id={'standings1'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Series' name='form_13_Series2'>
                            <Input id={'series2'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Class' name='form_14_Class2'>
                            <Input id={'class2'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Current Standings' name='form_15_CurrentStanding2'>
                            <Input id={'standings2'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Series' name='form_16_Series3'>
                            <Input id={'series3'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Class' name='form_17_Class3'>
                            <Input id={'class3'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Current Standings' name='form_18_CurrentStanding3'>
                            <Input id={'standings3'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Number of yearly races' name='form_19_NumberOfYearlyRaces'>
                            <Input type={'number'} id={'average-races'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Current GPA (if applicable)' name='form_20_GPA'>
                            <Input type={'number'} id={'gpa'} />
                        </Form.Item>
                    </Col>
                </Row>
                <h4>List All Series/Districts You Will be Racing in This Upcoming Season</h4>
                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Series' name='form_21_UpcomingSeries1'>
                            <Input id={'upcoming-series1'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Class' name='form_22_UpcomingClass1'>
                            <Input id={'upcoming-class1'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Series' name='form_23_UpcomingSeries2'>
                            <Input id={'upcoming-series2'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Class' name='form_24_UpcomingClass2'>
                            <Input id={'upcoming-class2'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Series' name='form_25_UpcomingSeries3'>
                            <Input id={'upcoming-series3'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Class' name='form_26_UpcomingClass3'>
                            <Input id={'upcoming-class3'} />
                        </Form.Item>
                    </Col>
                </Row>
                <hr />
                <h4>List your Bikes and ATVs</h4>
                <Row gutter={16}>
                    <Col xs={24} sm={4}>
                        <Form.Item label='Machine' {...validationConfig} name='form_27_MachineType1'>
                            <Select id={'machine-type1'}>
                                <Select.Option value={'bike'}>Bike</Select.Option>
                                <Select.Option value={'atv'}>ATV</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Make' {...validationConfig} name='form_28_MachineMake1'>
                            <Input id={'machine-make1'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Model' {...validationConfig} name='form_29_MachineModel1'>
                            <Input id={'machine-model1'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={4}>
                        <Form.Item label='Year' {...validationConfig} name='form_30_MachineYear1'>
                            <Input type={'number'} min={1900} id={'machine-year1'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={4}>
                        <Form.Item label='Machine' name='form_31_MachineType2'>
                            <Select id={'machine-type2'}>
                                <Select.Option value={'bike'}>Bike</Select.Option>
                                <Select.Option value={'atv'}>ATV</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Make' name='form_32_MachineMake2'>
                            <Input id={'machine-make2'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Model' name='form_33_MachineModel2'>
                            <Input id={'machine-model2'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={4}>
                        <Form.Item label='Year' name='form_34_MachineYear2'>
                            <Input type={'number'} min={1900} id={'machine-year2'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={4}>
                        <Form.Item label='Machine' name='form_35_MachineType3'>
                            <Select id={'machine-type3'}>
                                <Select.Option value={'bike'}>Bike</Select.Option>
                                <Select.Option value={'atv'}>ATV</Select.Option>
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Make' name='form_36_MachineMake3'>
                            <Input id={'machine-make3'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Model' name='form_37_MachineModel3'>
                            <Input id={'machine-model3'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={4}>
                        <Form.Item label='Year' name='form_38_MachineYear3'>
                            <Input type={'number'} min={1900} id={'machine-year3'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Race Plans for Next Season' name='form_39_RacePlans'>
                            <Input.TextArea rows={5} id={'race-plans'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Top Racing Accomplishments' className={'form-item-block'} name='form_40_RacingAccomplishment1'>
                            <Input id={'racing-accomplishment1'} />
                        </Form.Item>
                        <Form.Item className={'form-item-block'} name='form_41_RacingAccomplishment2'>
                            <Input id={'racing-accomplishment2'} />
                        </Form.Item>
                        <Form.Item className={'form-item-block'} name='form_42_RacingAccomplishment3'>
                            <Input id={'racing-accomplishment3'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Future Racing Goals' name='form_43_RacingGoals'>
                            <Input.TextArea rows={5} id={'racing-goals'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Why Should You Represent Moose Racing' name='form_44_WhyWouldYouBeGoodRep'>
                            <Input.TextArea rows={5} id={'why-would-you-be-a-good-rep'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Current Sponsors and Sponsorship Type' {...validationConfig} name='form_45_CurrentSponsors'>
                            <Input.TextArea rows={5} id={'current-sponsors'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label='How Did You Learn About Us' name='form_46_HowDidYouHearAboutUs'>
                            <Input.TextArea rows={5} id={'how-did-you-learn-about-us'} />
                        </Form.Item>
                    </Col>
                </Row>
                <hr />
                <h4>References</h4>
                <Row gutter={16}>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Name' name='form_47_ReferenceName1'>
                            <Input id={'reference-name1'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Email' name='form_48_ReferenceEmail1'>
                            <Input type={'email'} id={'reference-email1'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Phone Number' {...phoneValidationConfig} name='form_49_ReferencePhone1'>
                            <Input type={'phone'} id={'reference-phone1'} placeholder="xxx-xxx-xxxx"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Name' name='form_50_ReferenceName2'>
                            <Input id={'reference-name2'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Email' name='form_51_ReferenceEmail2'>
                            <Input type={'email'} id={'reference-email2'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Phone Number' {...phoneValidationConfig} name='form_52_ReferencePhone2'>
                            <Input type={'phone'} id={'reference-phone2'} placeholder="xxx-xxx-xxxx"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Name' name='form_53_ReferenceName3'>
                            <Input id={'reference-name3'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Email' name='form_54_ReferenceEmail3'>
                            <Input type={'email'} id={'reference-email3'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={8}>
                        <Form.Item label='Phone Number' {...phoneValidationConfig} name='form_55_ReferencePhone3'>
                            <Input type={'phone'} id={'reference-phone3'} placeholder="xxx-xxx-xxxx"/>
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Other Comments' name='form_56_comments'>
                            <Input.TextArea rows={7} id={'comments'} />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12}>
                        <Form.Item label='Social Media Pages' className={'form-item-block'} name='form_57_facebook'>
                            <Input placeholder='Facebook' prefix={<Icon type={'facebook'} style={{color: 'rgba(0,0,0,.25)'}}/>} id={'facebook'} />
                        </Form.Item>
                        <Form.Item className={'form-item-block'} name='form_58_twitter'>
                            <Input placeholder='Twitter' prefix={<Icon type={'twitter'} style={{color: 'rgba(0,0,0,.25)'}}/>} id={'twitter'} />
                        </Form.Item>
                        <Form.Item className={'form-item-block'} name='form_59_youtube'>
                            <Input placeholder='Youtube' prefix={<Icon type={'youtube'} style={{color: 'rgba(0,0,0,.25)'}}/>} id={'youtube'} />
                        </Form.Item>
                        <Form.Item name='form_60_instagram'>
                            <Input placeholder='Instagram' prefix={<Icon type={'instagram'} style={{color: 'rgba(0,0,0,.25)'}}/>} id={'instagram'} />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Form.Item>
                            <StyledButton type={'primary'} htmlType={'submit'}>Submit</StyledButton>
                        </Form.Item>
                        <div className='recaptchaPrivacyPolicy'>
                            <small>
                                This site is protected by reCAPTCHA and the Google <a href="https://policies.google.com/privacy">Privacy 
                                Policy</a> and <a href="https://policies.google.com/terms">Terms of Service</a> apply.
                            </small>
                        </div>
                    </Col>
                </Row>
            </Form>
        }
        </Spinner>
    );
}
