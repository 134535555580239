import { Spin } from 'antd';
import { useCategory } from 'lemans-brandsites-common/libs/ProductFeed';
import { IFeedProduct } from "lemans-brandsites-common/libs/ProductFeed/masterFeed.types";
import { useCategoryNav } from 'lemans-brandsites-common/libs/ProductFeed/useFeed';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { catUrl, imageUrl, prodUrl } from 'theme/libs/util';
import { first } from 'ts-functional';
import { Breadcrumbs } from '../Breadcrumbs';
import { getTag } from '../Product/Product.helpers';
import './Category.styles.less';
import { ICategoryProps } from "./Category.types";

export const Category = ({categoryId}:ICategoryProps) => {
    const [categoryData, loading] = useCategory(categoryId);
    const [nav ] = useCategoryNav();

    const catDetails = categoryData?.categories[0];
    const products = catDetails?.products;
    const categories = catDetails?.categories;

    const tag = getTag(
        "",
        nav?.catalogs[0].categories || [],
        catDetails?.breadcrumb || []
    );
    console.log(`Tag: ${tag}`);

    const productGroupName = (product:IFeedProduct) => 
        tag === "APPAREL" ? first(product.productGroups || [])?.productGroupName
                          : product.productName;

    return <div className="category">
        <Spin spinning={loading}>
            <Breadcrumbs crumbs={catDetails?.breadcrumb || []} />

            <div className="category-entities">
                {(categories || []).map(category => <div className="entity-list-item" key={category.categoryId}>
                    <Link to={catUrl(category.categoryId)}>
                        <img src={imageUrl(category, {x: 256, y: 256})} />
                    </Link>
                    <div className='title'>{category.categoryName}</div>
                </div>)}
            </div>

            <div className="category-entities">
                {(products || []).map(product => <div className="entity-list-item" key={product.productId}>
                    <Link to={prodUrl(product.productId, product.productGroupId)}>
                        <img src={imageUrl(product, {x: 256, y: 256})} />
                    </Link>
                    <div className='title'>
                        {productGroupName(product)}
                    </div>
                </div>)}
            </div>
        </Spin>
    </div>;

}
