import { FilePdfTwoTone } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { api } from 'lemans-brandsites-common/libs/api';
// import { imageUrl } from 'lemans-brandsites-common/libs/imageUrl';
import { solrParameters } from 'lemans-brandsites-common/libs/SolrParameters';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { prodTemplate } from 'theme/libs/config';
import { imageUrl } from 'theme/libs/util';
import './InstructionResults.styles.less';
import {IInstructionResultsProps} from "./InstructionResults.types";

export const InstructionResults = (props:IInstructionResultsProps) => {
    const [partPDFSource, setPartPDFSource] = React.useState<string[]>([]);

    // const onSearch = async () => {
    //     if (props.onSearch) {
    //         props.onSearch();
    //     }
    // }

    const getDownLoadLink = (partNo: any) => async (e: any) => {
        const configParams = await solrParameters();
        const urlObj = {
            ci: configParams.catalogInstanceId,
            locale: 'en',
            partNumber: `${partNo}`,
            rows: 500,
            templateName: prodTemplate,
        };

        try {
            const data = await api.get('lemans/api/feed-info/masterFeed').query(urlObj)
                .query({ vehicleTypeId: 18002 })
                .query({ vehicleTypeId: 18004 })
                .query({ vehicleTypeId: 18008 })
                .query({ vehicleTypeId: 18009 });

            const root = data.body.root;
            if (root[0] && root[0].domain[0].semantic) {
                const body = root[0].domain[0].semantic.find((sem: any) => sem.semanticName === "body");

                if (body.parts.length > 0) {
                    const mediaDetail = body.parts[0].media.filter((partMedia: any) =>
                        partMedia.viewingAngle.toLowerCase() === 'manual' && partMedia.extension.toLowerCase() === 'pdf'
                    )[0];

                    if (mediaDetail) {
                        const docSrc = {
                            extension: mediaDetail.extension,
                            mediaUrl: mediaDetail.mediaUrl,
                            prefixUrl: mediaDetail.prefixUrl,
                        };

                        window.open(imageUrl(docSrc), "");

                        // Set state to create anchor tag in case downlaod was blocked
                        setPartPDFSource({
                            ...partPDFSource,
                            [partNo]: imageUrl(docSrc)
                        });
                    }
                }
            }
        }
        catch (err) {
            console.log(err);
        }
    }

    const searchResults = (part: any, index: number) =>
        <Row key={`search-result-${index}`}>
            <Col span={8}>
                <Link to={`/parts/${part.partNumber}`}>
                    {part.punctuatedPartNumber}
                </Link>
            </Col>
            <Col span={8}>{part.partDescr}</Col>
            <Col className="text-center" span={8}>
                {partPDFSource[part.partNumber] &&
                    <a
                        href={partPDFSource[part.partNumber]}
                        target={'_blank'}
                        title={'Click again if download did not automatically start.'}
                    >
                        <FilePdfTwoTone twoToneColor="#ff0000" />
                    </a>
                }
                {!partPDFSource[part.partNumber] &&
                    <FilePdfTwoTone
                        onClick={getDownLoadLink(part.partNumber)}
                        twoToneColor="#ff0000"
                    />
                }
            </Col>
        </Row>;
        
    return (
        <>
            <Spinner isLoading={props.isLoading}>
                {/* {props.searchResults && props.searchResults.header &&
                    <SelectedFilters
                        facetHeader={props.searchResults.header}
                        onSearch={onSearch}
                    />
                } */}
                {props.searchResults && props.searchResults.hitlist && props.searchResults.hitlist.parts.length > 0 &&
                    <>
                        <h3>Results</h3>
                        <Row>
                            <Col span={8}><h4>Part #</h4></Col>
                            <Col span={8}><h4>Description</h4></Col>
                            <Col span={8}><h4 className="text-center">Instructions</h4></Col>
                        </Row>
                        {props.searchResults.hitlist.parts.map((part: any, index: number) =>
                            searchResults(part, index)
                        )}
                    </>
                }
                {props.searchResults && props.searchResults.length > 0 &&
                    <>
                        <h3>Results</h3>
                        <Row>
                            <Col span={8}><h4>Part #</h4></Col>
                            <Col span={8}><h4>Description</h4></Col>
                            <Col span={8}><h4 className="text-center">Instructions</h4></Col>
                        </Row>
                        {props.searchResults.map((part: any, index: number) =>
                            searchResults(part, index)
                        )}
                    </>
                }
                {props.searchResults && props.searchResults.hitlist && props.searchResults.hitlist.parts && props.searchResults.hitlist.parts.length === 0 &&
                    <Row>
                        <Col span={24}>
                            No results found: We were unable to find any Instruction Manuals using the selected filters.
                        </Col>
                    </Row>
                }
                {props.searchResults && props.searchResults.length === 0 && props.errorState !== false &&
                    <Row>
                        <Col span={24}>
                            No results found: We were unable to find any Instruction Manuals using the selected filters.
                        </Col>
                    </Row>
                }
            </Spinner>
        </>
    );
}