import { api } from 'lemans-brandsites-common/libs/api';
import { solrParameters } from 'lemans-brandsites-common/libs/SolrParameters';
import { parse } from 'querystring';
import * as React from 'react';
import 'theme/components/Search/Search.styles.less';
import { SearchResults } from 'theme/components/SearchResults';
import { Spinner } from '../Spinner';
import { ISearchProps } from "./Search.types";

export const Search = (props: ISearchProps) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [mmyResults, setMMyResults] = React.useState([]);

    const getSearchResults = () => {
        const queryParams = parse(window.location.search.substring(1));
        solrParameters().then(configParams => {
            if (typeof queryParams.q !== undefined) {
                setIsLoading(true);
                api.get(`lemans/api/solr-info/search`).query({
                    ...queryParams,
                    ci: configParams.catalogInstanceId,
                    docType: "productGroup",
                    rows: 500,
                })
                .then(api.getBody)
                .then(setMMyResults)
                .finally(() => {setIsLoading(false);});
            } else {
                setMMyResults([]);
            }
        });
    }

    React.useEffect(getSearchResults, [window.location.search]);

    return (
        <div className="Search">
            { isLoading &&
                <Spinner isLoading={isLoading} defaultIndicator={true} size="large" />
            }
            { !isLoading && mmyResults &&
                <SearchResults mmyResults={mmyResults} isLoading={false} onSearch={getSearchResults} />
            }
        </div>
    );
}
