import { FilePdfTwoTone } from '@ant-design/icons';
import { Col, Row, Spin } from 'antd';
import { PartSpecs } from 'lemans-brandsites-common/components/PartSpecs/PartSpecs.component';
import { usePart } from 'lemans-brandsites-common/libs/ProductFeed/useFeed';
import * as React from 'react';
import { imageUrl, prodUrl } from 'theme/libs/util';
import { Breadcrumbs } from '../Breadcrumbs';
import './Part.styles.less';
import { IPartProps } from "./Part.types";

export const Part = ({partNumber}:IPartProps) => {
    const [data, loading] = usePart(partNumber);

    const part = data?.parts[0];

    const instructionMedia = part?.media.filter((med:any) =>  med.viewingAngle.toLowerCase() === "manual" && med.extension === "pdf");
    const docSrc = instructionMedia && instructionMedia[0] !== undefined ? {
            extension: !!instructionMedia[0].extension ? instructionMedia[0].extension : '',
            mediaUrl: !!instructionMedia[0].mediaUrl ? instructionMedia[0].mediaUrl : '',
            prefixUrl: !!instructionMedia[0].prefixUrl ? instructionMedia[0].prefixUrl : '',
        } : 
        undefined;

    return <Spin spinning={loading}>
        {!!part && <Row className="part">
            <Col xs={24} lg={12}>
                <img src={imageUrl(part, { x: 480, y: 480, t: 'image/jpeg' })} />
            </Col>
            <Col xs={24} lg={12}>
                <Breadcrumbs crumbs={part.breadcrumb} extra={[{url: prodUrl(part.productId, part.productGroupId), text: part.productName}]} />
                <h1 className="productTitle">
                    <div className="productName">{part.partDescr}</div>
                    <div className="productGroupName">{part.punctuatedPartNumber}</div>
                    <div className="msrp">{part.retailPriceDisplay}</div>
                </h1>
                <PartSpecs specs={part.attributes} />
                <br />
                {!!docSrc && docSrc !== undefined && 
                    <div className='instruction'>
                        <a
                            href={!!docSrc ? imageUrl(docSrc) : ''}
                            target={'_blank'}
                            title={'Click again if download did not automatically start.'}
                        >
                            INSTALLATION INSTRUCTIONS <FilePdfTwoTone twoToneColor="#ff0000" />
                        </a>
                    </div>
                }
            </Col>
        </Row>}
    </Spin>;
}
