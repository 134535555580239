import { IDealerData } from 'lemans-brandsites-common/libs/DealerLocatorApi/libs/OnlineDealer/onlineDealer.types';

// What gets passed into the component from the parent as attributes
export interface IDealerLocatorMapProps {
    apiKey: string;
    dealerList?: IDealerData[];
    defaultLat?: number;
    defaultLng?: number;
    dotUri?: string;
    featuredDotUri?: string;
    featuredMarkerDir?: string;
    featuredMarkerImageName?: string;
    markerDir?: string;
    offPageDealerList?: IDealerData[];
    selectedDealerIndex?: number;
    theme?: mapTheme;
}

export enum mapTheme {
    Standard,
    Silver,
    Retro,
    Dark,
    Night,
    Aubergine
}
