import { IPage } from 'lemans-brandsites-common/libs/api.types';
import { page } from 'lemans-brandsites-common/libs/GetPageFromWP';
import * as React from 'react';
import './StaticPageVideo.styles.less';
import { IStaticPageVideoProps } from "./StaticPageVideo.types";

export const StaticPageVideo = ({slug}:IStaticPageVideoProps) => {
    const [data, setData] = React.useState<IPage | null>(null);

    React.useEffect(() => {
        page(slug).then(setData);
    }, [slug]);

    console.log(data);
    const videos:Array<{banner_image: string, video_src: string}> = data?.acf?.videos;

    return <>
        {!!videos && <>
            {videos.map(video => <div key={video.video_src} className="staticPage-video-wrapper">
                <iframe
                    allowFullScreen
                    className="video"
                    frameBorder={0}
                    src={`https://www.youtube.com/embed/${video.video_src.replace("youtube-", "")}?autoplay=0`}
                    allow="autoplay"
                />
            </div>)}
        </>}
    </>;
}
