import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import clsx from 'clsx';
import { useToggle } from 'lemans-brandsites-common/libs/useToggle';
import * as React from 'react';
import { useHistory } from 'react-router-dom';
import './QuickSearch.styles.less';
import { IQuickSearchProps } from "./QuickSearch.types";

export const QuickSearch = (props:IQuickSearchProps) => {
    const showSearch = useToggle();
    const history = useHistory();

    const [term, setTerm] = React.useState("");
    const updateTerm = (e:React.ChangeEvent<HTMLInputElement>) => {
        setTerm(e.currentTarget.value);
    }

    const search = () => {
        history.push(`/search?q=${term}`);
        showSearch.off();
        setTerm("");
        if(props.onSearch) {
            props.onSearch();
        }
    }

    return <span className={clsx([
        "QuickSearch", "nav-item", "gray",
        (showSearch.isOn || props.inline) && "active",
        props.inline && "inline",
        !props.inline && "floating",
    ])}>
        {(showSearch.isOn || props.inline) && <Input
            placeholder="Search for..."
            autoFocus
            onChange={updateTerm}
            onPressEnter={search}
        />}
        {!props.inline && <span onClick={showSearch.toggle} >
            <SearchOutlined className="header-link-icon"/>
            <span className="header-link-label">SEARCH</span>
        </span>}
    </span>;
}