import { IFeedProduct, IFeedProductGroup } from 'lemans-brandsites-common/libs/ProductFeed/masterFeed.types';
import * as React from 'react';
import './ProductTitle.styles.less';
import { IProductTitleProps } from "./ProductTitle.types";

const getNameDefault    = (product:IFeedProduct, productGroup:IFeedProductGroup) => product.productName;
const getSubNameDefault = (product:IFeedProduct, productGroup:IFeedProductGroup) => productGroup.productGroupName;

export const ProductTitle = ({product, productGroup, getName, getSubName}:IProductTitleProps) => {
    const name = (getName || getNameDefault)(product, productGroup);
    const subName = (getSubName || getSubNameDefault)(product, productGroup);

    return <h1 className="productTitle">
        <div className="productName">{name}</div>
        {subName !== name && <div className="productGroupName">
            {subName}
        </div>}
        <div className="msrp">{productGroup.retailPriceDisplayRange}</div>
    </h1>;
}
