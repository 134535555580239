import { Col, Modal, Row } from 'antd';
import { Image } from 'lemans-brandsites-common/components/Image/Image.component';
import { Page } from 'lemans-brandsites-common/components/Page';
import { SpinnerCss } from 'lemans-brandsites-common/components/SpinnerCss';
import { pageRestHandleSideEffects } from 'lemans-brandsites-common/libs/apiHandleSideEffects';
import { siteConfig } from 'lemans-brandsites-common/libs/config';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { SectionTitle } from 'theme/components/SectionTitle';
import { brandName } from 'theme/libs/config';
import { FourOhFour } from '../FourOhFour';
import { InstagramFeed } from '../InstagramFeed/InstagramFeed.component';
import { MMYComponent } from '../MMY';
import { NewsLetterSignUp } from '../NewsLetterSignUp';
import './Homepage.styles.less';
import { IFeaturedPills, IHomepageProps, ISlide } from "./Homepage.types";

export const Homepage = (props: IHomepageProps) => {
    const [displayFourOhFour, isLoading, data] = pageRestHandleSideEffects('home');
    const [currentSlide, setCurrentSlide] = useState<ISlide | null>(null);
    const [isFading, setisFading] = useState(false);
    const [slides, setSlides] = useState<ISlide[]>([]);
    const [featuredLargePills, setFeaturedLargePills] = useState<IFeaturedPills[]>([]);
    const [featuredSmallPills, setFeaturedSmallPills] = useState<IFeaturedPills[]>([]);
    const [isNewsletterModalVisible, setIsNewsletterModalVisible] = useState(true);

    const slideLength = data?.acf.slider_images.length;
    const interval:number = data?.acf.transition_interval ? data?.acf.transition_interval : 20;

    let slideIndex = 0;

    const millisecondsToHours = (date: number) => date / (1000 * 3600);

    const isChoiceExpired = (dateChosen: number) => {
        // set expiration time as 3 days
        const expirationHours = 36;
        const timeDifference = (new Date()).getTime() - dateChosen;

        console.log(millisecondsToHours(timeDifference));

        return millisecondsToHours(timeDifference) > expirationHours;
    }

    useEffect(() => {
        setSlides(data?.acf.slider_images as ISlide[]);
        setFeaturedLargePills(data?.acf.featured_pills_large as IFeaturedPills[]);
        setFeaturedSmallPills(data?.acf.featured_pills_small as IFeaturedPills[]);
        setCurrentSlide(data?.acf.slider_images[slideIndex]);
        slideIndex++;

        const chosenDate = Date.parse(localStorage.chosenDate);
        
        if(localStorage.getItem('hasVisited') === "failed") {
            localStorage.clear();
            setIsNewsletterModalVisible(true);
        } else {
            if( isChoiceExpired(chosenDate) ) {
                localStorage.clear();
                setIsNewsletterModalVisible(true);
            } else {
                if (localStorage.getItem('hasVisited') === null) {
                    setIsNewsletterModalVisible(true);
                } else {
                    setIsNewsletterModalVisible(false);
                }
            }
        }
    }, [data]);

    useEffect(() => {
        let timer: number | undefined;
        if (!!interval) {
            timer = window.setInterval(() => {
                setCurrentSlide(data?.acf.slider_images[slideIndex]);
                slideIndex < slideLength - 1
                    ? slideIndex++
                    : slideIndex = 0;
            }, interval * 1000);
        }
        return () => window.clearInterval(timer);
    }, [data]);

    const goToSlide = (index: number) => () => {
        setisFading(true);
        setTimeout(() => {
            setisFading(false);
        }, 700);
        setCurrentSlide(data?.acf.slider_images[index]);
    }

    const handlenewsLetterModalCancel = () => {
        const chosenDate = new Date();
        localStorage.chosenDate = chosenDate;
        if(localStorage.getItem('hasVisited') === "failed") {
            localStorage.clear();
            setIsNewsletterModalVisible(false);
        } else {
            setIsNewsletterModalVisible(false);
            localStorage.setItem('hasVisited', 'yes');
        }
    }

    return (
        <>
            { displayFourOhFour && <FourOhFour />}
            { isLoading && <SpinnerCss />}
            { !isLoading && !!currentSlide &&
                <Page
                    className="HomePage"
                    description={`${brandName} - Home`}
                    robots={{ follow: true, index: true }}
                    title={`${brandName} - Home`}
                >
                    <Modal
                        className="newsletter-modal"
                        destroyOnClose={true}
                        footer={null}
                        onCancel={handlenewsLetterModalCancel}
                        open={isNewsletterModalVisible}
                        width={'60vw'}
                    >
                        <NewsLetterSignUp />
                    </Modal>
                    <div id="img-preload" style={{ display: "none" }}>
                        {slides.map(slide => <Image key={slide.image_title} src={slide.slider_image} title={`${slide.image_title}`}/> )}
                    </div>
                    <div className="background">
                        {currentSlide.slider_image_url !== '' && <a href={currentSlide.slider_image_url} key={currentSlide.image_title}>
                            {/* <div
                                className={`slider ${isFading ? "fadeIn" : ""}`}
                                style={{ backgroundImage: `linear-gradient(to left, ${data?.acf.background_overlay}, ${data?.acf.background_overlay}), url(${siteConfig.imageBase}${currentSlide.slider_image})` }}
                            /> */}
                            <img className={`desktopSlider slider ${isFading ? "fadeIn" : ""}`} src={`${siteConfig.imageBase}${currentSlide.slider_image}`} />
                            <img className={`mobileSlider slider ${isFading ? "fadeIn" : ""}`} src={`${siteConfig.imageBase}${currentSlide.mobile_image}`} />
                        </a> } 
                        {currentSlide.slider_image_url === '' && 
                            // <div
                            //     className={`slider ${isFading ? "fadeIn" : ""}`}
                            //     style={{ backgroundImage: `linear-gradient(to left, ${data?.acf.background_overlay}, ${data?.acf.background_overlay}), url(${siteConfig.imageBase}${currentSlide.slider_image})` }}
                            // /> 
                            <>
                            <img className={`desktopSlider slider ${isFading ? "fadeIn" : ""}`} src={`${siteConfig.imageBase}${currentSlide.slider_image}`} />
                            <img className={`mobileSlider slider ${isFading ? "fadeIn" : ""}`} src={`${siteConfig.imageBase}${currentSlide.mobile_image}`} />
                            </>
                        } 
                    </div>
                    <div className="timeline-wrapper">
                        <div className="timeline">
                            <div className="timeline__list">
                                {slides.map((slide: ISlide, i: number) =>
                                    <div
                                        className={`${slide.image_title === currentSlide.image_title
                                            ? 'timeline__item timeline__item--active'
                                            : 'timeline__item'}`}
                                        key={slide.image_title}
                                        onClick={goToSlide(i)}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                    <br />
                    <div className='mmy-search-wrapper'>
                        <MMYComponent />
                    </div>
                    <br />
                    <div className="featured-pills-big">
                        <Row gutter={[18,18]}>  
                            {featuredLargePills.map((pill,i) => 
                                <Col key={i} xs={24} sm={12} lg={8}>
                                    {pill.overlay_text !== '' &&
                                    <>
                                        {!!pill.featured_image && <Image key={pill.overlay_text} src={pill.featured_image} title={`${pill.overlay_text}`}/> }
                                        <a href={pill.img_url} key={pill.img_url}>
                                            <SectionTitle
                                                side={'center'}
                                                verticalPos={'center'}
                                                title={pill.overlay_text}
                                            />
                                        </a>
                                    </>
                                    }
                                    {pill.overlay_text === '' &&
                                        !!pill.featured_image && <a href={pill.img_url} key={pill.img_url}><Image key={pill.overlay_text} src={pill.featured_image} title={`${pill.overlay_text}`}/> </a>
                                    }
                                </Col>
                            )}

                        </Row>
                    </div>
                    <div className="featured-pills-small">
                        <Row gutter={[12,12]}>
                            {featuredSmallPills.map((pill, i) => 
                                <Col key={i} xs={12} sm={8} lg={4}>
                                    {pill.overlay_text !== '' &&
                                    <>
                                        {!!pill.featured_image && <Image key={pill.overlay_text} src={pill.featured_image} title={`${pill.overlay_text}`}/> }
                                        <a href={pill.img_url} key={pill.img_url}>
                                            <SectionTitle
                                                side={'center'}
                                                verticalPos={'center'}
                                                title={pill.overlay_text}
                                            />
                                        </a>
                                    </>
                                    }
                                    {pill.overlay_text === '' &&
                                        !!pill.featured_image && <a href={pill.img_url} key={pill.img_url}><Image key={pill.overlay_text} src={pill.featured_image} title={`${pill.overlay_text}`}/> </a>
                                    }
                                </Col>
                            )}
                        </Row>
                    </div>
                    <div className='instagram_container'>
                        <InstagramFeed />  
                    </div>
                    
                </Page>
            }
        </>
    );
}