import React from 'react';
import { NavDesktop } from '../NavDesktop';
import { NavMobile } from '../NavMobile';
import './Header.styles.less';

export const Header: React.FC = () => {
  return <>
    <NavDesktop />
    <NavMobile />
  </>;
};
