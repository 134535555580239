import { CloseOutlined, VideoCameraOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import { IEntityMedia } from 'lemans-brandsites-common/libs/ProductFeed/masterFeed.types';
import * as React from 'react';
import 'theme/components/MediaSwitcher/MediaSwitcher.styles.less';
import { imageUrl } from 'theme/libs/util';
import { IMediaSwitcherProps } from "./MediaSwitcher.types";

export const MediaSwitcher = (props:IMediaSwitcherProps) => {
    const [curMedia, setCurMedia] = React.useState(0);
    const select = (index:number) => () => {setCurMedia(index);}

    const getVideoId = (vid?:IEntityMedia) => !!vid ? vid.mediaUrl.replace("youtube-", "") : "";
    const [videoId, setVideoId] = React.useState("");
    const updateVideo = (vid?:IEntityMedia) => () => {setVideoId(getVideoId(vid));}

    const isNormalImage = (media:IEntityMedia) => 
        media.mediaTypeDescription === "image" &&
        media.tag === "";

    const isVideo = (media:IEntityMedia) => media.mediaTypeDescription === "video";

    const images = !!props.media ? props.media.filter(isNormalImage) : [];
    const videos = !!props.media ? props.media.filter(isVideo)       : [];

    const thumbnailUrl = (vid:IEntityMedia) => props.thumbnailUrl || `http://img.youtube.com/vi/${getVideoId(vid)}/sddefault.jpg`;

    return props.media && props.media.length > 0
        ? <div className={clsx(["media-switcher", props.inline && "inline"])}>
            {!!videoId && <div className="video-container">
                <div className="video-close" onClick={updateVideo()}>
                    Close <CloseOutlined />
                </div>
                <iframe
                    allowFullScreen
                    className="video"
                    frameBorder={0}
                    src={`https://www.youtube.com/embed/${videoId}?autoplay=1`}
                    allow="autoplay"
                    style={{width: "100%"}}
                />
            </div>}
            <Row>
                {!props.inline && <Col xs={24} className="media-view">
                    <img src={imageUrl(images[curMedia], { x: 512, y: 512, t: 'image/jpeg' })}/>
                </Col>}
                {!props.noImages && images.map((media, i) =>
                    <Col className="media-item" key={`image-${media.mediaId}`} xs={props.inline ? 24 : 6}>
                        <img src={imageUrl(media, { x: 256, y: 256, t: 'image/jpeg' })} onClick={select(i)}/>
                    </Col>
                )}
                {!props.noVideo && videos.map((vid, i) =>
                    <Col className="media-item video-item" key={`video-${i}`} xs={props.inline ? 24 : 6} onClick={updateVideo(vid)}>
                        {props.showHoverIcon && <div className="icon"><VideoCameraOutlined /></div>}
                        <img src={thumbnailUrl(vid)}/>
                    </Col>
                )}
            </Row>
        </div>
        : <div>No media</div>;
}
