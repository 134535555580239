import * as React from "react";
import './Page.styles.less';
import { IPageProps } from "./Page.types";

export const Page = (props: IPageProps) => {
    document.title = props.title;
    const metaDescription = document.querySelector('meta[name="description"]');
    const metaRobots = document.querySelector('meta[name="robots"]');

    if (metaDescription && props.description) {
        metaDescription.setAttribute("content", props.description);
    }

    if (metaRobots && props.robots) {
        const followText = props.robots.follow ? 'follow' : 'nofollow';
        const indexText = props.robots.index ? 'index' : 'noindex';

        metaRobots.setAttribute("content", `${indexText}, ${followText}`);
    }

    return (
        <div className={'page-content--div' + (props.className ? ` ${props.className}` : '')}>
            {React.Children.map(props.children, a => a)}
        </div>
    );
}