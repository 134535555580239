import { Col, Row } from 'antd';
import { api } from 'lemans-brandsites-common/libs/api';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { FourOhFour } from 'theme/components/FourOhFour';
import { Spinner } from '../Spinner';
import './News.styles.less';
import { INewsProps } from "./News.types";

export const News = (props:INewsProps) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [newsData, setNewsData] = React.useState<any>(undefined);
    const [selectedNews, setSelectedNews] = React.useState<any>(undefined);
    
    const getNewsData = async () => {
        setIsLoading(true);
        try {
            const recentPosts = await (!newsData ? api.get(`wp/v2/posts`).query({}) : {body: newsData});
            const currentPost = await (props.slug ? api.get(`wp/v2/posts`).query({slug: props.slug}) : recentPosts);

            setNewsData(recentPosts.body);
            setSelectedNews(currentPost.body[0]);
        }
        catch (e) {
            // tslint:disable
            console.log(e);
        }
        finally {
            setIsLoading(false);
        }
    }


    React.useEffect( () => {
        getNewsData();
    }, [props.slug]);

    const formattedDate = () => {
        const tempDate = new Date(selectedNews.date);

        const monthNames = ["January", "February", "March", "April", "May", "June",
            "July", "August", "September", "October", "November", "December"
        ];
        const monthName = monthNames[tempDate.getMonth()];
        return `${monthName} ${tempDate.getDate()}, ${tempDate.getFullYear()}`;
    }

    return <>
        {isLoading && 
            <Spinner />
        }
        {!isLoading && newsData && selectedNews && <>
            <div className="newsPage">
                <div className="postHeader" dangerouslySetInnerHTML={{__html: selectedNews.title.rendered}}/>
                <div className="postHeaderDate">{formattedDate()}</div> 
                <img className="featuredImage" src={selectedNews.acf.featured_image} />
                <div className="postContent" dangerouslySetInnerHTML={{__html: selectedNews.content.rendered}}/>
                <div className="recentPostsTitle">Recent Articles</div>
                <Row gutter={16} className="recentPostsContainer">
                    {newsData.map((news: any, index:number) => 
                        <Col span={8} key={`recentPostkey-${index}`}>
                            <div className="recentPost">
                                <Link to={'/news/' + news.slug}>
                                    <img src={news.acf.featured_image} />
                                    <div className="postTitle" dangerouslySetInnerHTML={{__html: news.title.rendered}} />
                                </Link>  
                            </div>
                        </Col>
                    )}
                </Row>
            </div>
        </>}
        { !isLoading && (!newsData || !selectedNews) &&
            <FourOhFour />
        }
    </>;
}
