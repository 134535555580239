import * as React from "react";
import { FeatureList } from './FeatureList';
import { NoteList } from './NoteList';
import './ProductFeatures.styles.less';
import { IProductFeaturesProps } from "./ProductFeatures.types";
import { SubFeatureList } from './SubFeatureList';
import { WarningList } from './WarningList';

export const ProductFeatures = (props:IProductFeaturesProps) => {    
    const features    = !!props.data ? props.data.filter(item => item.featureType.toLowerCase() === 'bullet' ) : [];
    const subfeatures = !!props.data ? props.data.filter(item => item.featureType.toLowerCase() === 'sub'    ) : [];
    const warnings    = !!props.data ? props.data.filter(item => item.featureType.toLowerCase() === 'warning') : [];
    const notes       = !!props.data ? props.data.filter(item => item.featureType.toLowerCase() === 'note'   ) : [];

    return !!props.data ?
        <div className={'product-features-wrapper--div' + (props.className ? ` ${props.className}` : '')}>
            { features.length > 0 && 
                <FeatureList data={features} />
            }
            { subfeatures.length > 0 &&
                <SubFeatureList data={subfeatures} />
            }
            { warnings.length > 0 &&
                <WarningList data={warnings} />
            }
            { notes.length > 0 && 
                <NoteList data={notes} />
            }
        </div>
    : null;
}
