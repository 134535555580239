import * as React from 'react';
import { createRoot } from 'react-dom/client';
import './index.less';
import registerServiceWorker from './registerServiceWorker';
import { App } from './theme/components/App';

const root = createRoot(document.getElementById('root') as HTMLElement);
root.render(<App />);

registerServiceWorker();
