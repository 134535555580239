import { DownSquareOutlined, PlusOutlined, UpSquareOutlined } from '@ant-design/icons';
import { Checkbox } from 'antd';
import { StyledCheckBox } from 'lemans-brandsites-common/components/StyledCheckBox';
import * as React from 'react';
import { Link } from 'react-router-dom';
import 'theme/components/SearchFacetFilters/SearchFacetFilters.styles.less';
import { ISearchFacetFiltersProps } from "./SearchFacetFilters.types";

export const SearchFacetFilters = (props:ISearchFacetFiltersProps) => {
    const [checkedVals, setCheckedVals] = React.useState<any>();
    const [filteredFacets, setFilteredFacets] = React.useState([]);
    const [showIndividualFacetBlock, setShowIndividualFacetBlock] = React.useState([] as boolean[]);

    React.useEffect(() => {
        const tempFilteredFacets = props.availableFacets.filter((facet:any) =>  props.facetHeader.facets.find((facetHeader:any) => facet.name !== facetHeader.values[0].name));
        setFilteredFacets(tempFilteredFacets);

        // create an empty array of arrays (for each facet available for narow results) and assign it to a state variable
        const checkedValsArray = tempFilteredFacets.map((filteredFacet:any) => 
            []
        )
        setCheckedVals(checkedValsArray);

        // create an array of arrays with all the individual facet blocks set to true to start with - 
        // this is for controlling show hide of the facet block under "narrow your results"
        setShowIndividualFacetBlock(tempFilteredFacets.map((filteredFacet:any) => true));
    },[props.availableFacets] )

    const onChange = (index:number) => (checkedValues:[]) => {
        const checkedValuesArray = checkedVals.map((checkedVal:any, checkedValIndex:number) => {
            if(index === checkedValIndex) {
                return checkedValues;
            } else {
                return [];
            }
        })
        setCheckedVals(checkedValuesArray);
    }

    const addSelected = (type:any, index:number) => {        
        let queryStr = '';
        checkedVals[index].map((checkedVal:any, checkedValIndex:number) => {
            queryStr += `&${type}[${checkedValIndex}]=${checkedVal}`;
        })

        const queryParams = new URLSearchParams(window.location.search);
        const newQueryStr = queryParams.toString() + queryStr.toString();
        return decodeURIComponent(window.location.pathname + '?' + newQueryStr);
    }

    const toggleIndividualFacetBlock = (index:number) => () => {
       const tempArray = showIndividualFacetBlock.map((showIndividualFacet:any, showIndividualFacetIndex:number) => {
           if(showIndividualFacetIndex === index) {
                return !showIndividualFacet;
            } else {
                return showIndividualFacet;
            }
       })
       setShowIndividualFacetBlock(tempArray);
    }     

    return (        
        <>
        <div className="selectedFilterTitle">Narrow Your Results</div>
            <div className="narrowFiltersContainer">
                {filteredFacets.map((availableFacet:any, index:number) => 
                     availableFacet.values && availableFacet.values.length > 0 &&
                        <div className="availableFacetsBlock" key={`availableFacetsBlockKey${index}`}>
                            <div className="availableFacetTitle" key={`availableFacetTitleKey${index}`}>
                            {showIndividualFacetBlock[index] ? <UpSquareOutlined onClick={toggleIndividualFacetBlock(index)}/> : <DownSquareOutlined onClick={toggleIndividualFacetBlock((index))}/> }
                                &nbsp;{availableFacet.name}
                            </div>
                            <div id={`availableFacetsBlockInner${index}`} hidden={!showIndividualFacetBlock[index]}>
                                <StyledCheckBox onChange={onChange(index)} value={checkedVals[index]} key={`availableFacetChkBoxGrpKey${index}`}>
                                    {checkedVals[index].length > 0 &&
                                    <Link to={addSelected(availableFacet.type, index)} key={`add-selected-button-link-key${index}`}>
                                        <div className="addSelected" key={`add-selected-styled-button-key${index}`}><PlusOutlined />Add Selected</div>
                                    </Link>
                                    }
                                    {
                                    availableFacet.values.map((facetValue:any, facetValueIndex:number) => 
                                        <div className="individualSearchFilter" key={`individualSearchFilter${facetValueIndex}`}>
                                            <Checkbox value={facetValue.id} key={`available-chk-key-${facetValueIndex}`}>
                                                <Link className="available-facet--link" to={'/search?' + window.location.search + `&${availableFacet.type}[${facetValueIndex}]=${facetValue.id}`} key={`available-facet-link__key${facetValueIndex}`}>
                                                    <span className="searchFilter" key={`key-${index}`} ><span dangerouslySetInnerHTML={{__html:facetValue.name}}/>{` (${facetValue.count})`}</span>
                                                </Link>
                                            </Checkbox>
                                        </div>
                                    )
                                    }
                                </StyledCheckBox>
                            </div>
                        </div> 
                )
                }
            </div>
        </>
    );

}
