// Place for defining consts which needs to be passed to shared/common components
export const contactFormKey = "moose_cu";
export const newsletterKey = 'moose_newsletter';
export const gAnalyticsId   = 'UA-18514988-1';
export const gaMeasurementId   = 'G-20M0Y9JN2E';

// TODO: this is a temporary change, should be changed back to local.mooseracing.com after the development is complete
export const localHostUrl   = "localhost:3000";
export const localSiteUrl   = `http://${localHostUrl}`;
export const localApiUrl    = `http://${localHostUrl}`;
export const localImageBase = `http://${localHostUrl}`;

export const stageHostUrl   = `stage.mooseracing.com`;
export const stageSiteUrl   = `https://${stageHostUrl}`;
export const stageApiUrl    = stageSiteUrl;
export const stageImageBase = stageSiteUrl;

export const readyHostUrl   = `ready.mooseracing.com`;
export const readySiteUrl   = `https://${readyHostUrl}`;
export const readyApiUrl    = readySiteUrl;
export const readyImageBase = readySiteUrl;

export const prodHostUrl   = `www.mooseracing.com`;
export const prodSiteUrl   = `https://${prodHostUrl}`;
export const prodApiUrl    = prodSiteUrl;
export const prodImageBase = prodSiteUrl;

export const navTemplate      = "nav2023";
export const catalogTemplate  = "";
export const categoryTemplate = "category2023";
export const partTemplate     = "part2023";
export const prodTemplate     = "product2023";
export const prodGrpTemplate  = "product2023";

export const siteKey           = "";
export const prodMapsKey       = "AIzaSyCQ3yplReWxAHRwqO9DLq91TKbxbCWPW3w";
// export const prodMapsKey       = "AIzaSyCKLPoKMTbDZwBOEQuzayYjEUUUZ2hDpFg";
export const devMapsKey        = "AIzaSyCKLPoKMTbDZwBOEQuzayYjEUUUZ2hDpFg";
export const youTubeKey        = "";
export const youTubePlaylistId = "";
export const brandName         = "Moose Racing";
export const spinnerLogo       = "/spinnerLogo.png";

export const facebookUrl  = "https://www.facebook.com/OfficialMooseRacing/";
export const instagramUrl = "https://www.instagram.com/mooseelite/";
export const twitterUrl   = "https://twitter.com/mooseracing/";
export const youTubeUrl   = "https://www.youtube.com/user/OfficialMooseRacing/";

export const domain = 5;

// Settings for Pixlee Instagram feed
export const pixlee = {
    apiKey: 'wmA6wnrd_VNm_4In7vuQ',    
    widgetId: '37498',
};

// <div id="pixlee_container"></div><script type="text/javascript">window.PixleeAsyncInit = function() {Pixlee.init({apiKey:'wmA6wnrd_VNm_4In7vuQ'});Pixlee.addSimpleWidget({widgetId:'37498'});};</script><script src="//instafeed.assets.pxlecdn.com/assets/pixlee_widget_1_0_0.js"></script>