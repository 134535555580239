import { Col, Row } from 'antd';
import { SocialMedia } from 'lemans-brandsites-common/components/SocialMedia/SocialMedia.component';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { injectNav } from 'theme/libs/useNav';
import { catUrl } from 'theme/libs/util';
import { inject, mergeProps } from 'unstateless';
import './Footer.styles.less';
import { FooterProps, IFooterInputProps } from "./Footer.types";

const connect = inject<IFooterInputProps, FooterProps>(mergeProps(
    injectNav,
));

export const Footer = connect(({categories}: FooterProps) => <div className="footer">
    <Row>
        <Col xs={8} md={6} className="brand-column">
            <div className="footer-logo">
                <img src="/Footer-Logo.jpg" />
            </div>
            <div className="social-icons">
                <SocialMedia/>
            </div>
        </Col>
        <Col xs={16} md={18} className="footer-links">
            <div className="footer-categories">
                {categories.map(cat => <Link
                    key={cat.categoryId}
                    to={catUrl(cat.categoryId)}
                >
                    {cat.categoryName}
                </Link>)}
            </div>
            <div className="other-links">
                <Link to="/sponsorship">Sponsorship</Link>
                {/* <Link to="/news">Blog</Link> */}
                <Link to="/dealer-search">DEALER LOCATOR</Link>
                <Link to="/catalog">Catalog</Link>
                <Link to="/videos">Videos</Link>
                <Link to="/bike-builds">Bike Builds</Link>
                <Link to="/size-charts">SIZE CHARTS</Link>
                <Link to="/installation-instructions">Installation Instructions</Link>
                <Link to="/help-center">Help Center</Link>
                <Link to="/our-story">Our Story</Link>
            </div>
            <div className="footer-disclaimer">
                This site is protected by ReCaptcha and the Google <a href="https://policies.google.com/privacy?hl=en-US"> Privacy Policy</a> and <a href="https://policies.google.com/terms?hl=en-US">Terms of Service</a> apply.
            </div>
        </Col>
    </Row>
    <div className="bottom-links">
        <Link to="/legal">Legal</Link>
        <Link to="/privacy-policy">Privacy Policy</Link>
        <Link to="http://cpsc.lemansnet.com/">Conformity Certificates</Link>
        <span>© {new Date().getFullYear()} LeMans Corporation. All rights reserved.</span>
    </div>
</div>);