import { merge } from 'atp-pointfree';
import request from 'superagent';
import { apiConfig } from './config';

const restUrl = (url:string) => `${apiConfig.host}${apiConfig.restBase}${url}`;

export const api = {
    get: (url:string) => request
        .get(restUrl(url))
        .set('Accept', 'application/json'),
    getBody: (response:any) => response.body,
    getCustomFields: (response:any) => response.body[0].acf,
    getPage: (slug:string) => api.get(`wp/v2/pages`).query({slug}),
    parseQuery: (query:{}) => Object.keys(query).map((key:string) => ({
        [key]: Array.isArray(query[key]) ? query[key].join(",") : query[key]
    })).reduce(merge),
    post: (url:string, payload:{}, type?: 'json'|'form') => request
        .post(restUrl(url))
        .set('Accept', 'application/json')
        .type(type || 'json')
        .send(payload),
    put: (url: string, payload:{}) => request
        .put(restUrl(url))
        .set('Accept', 'application/json')
        .send(payload),
};