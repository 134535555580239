export const imageUrl = (fallbackImage:string) => (imageData:IMediaUrlData, queryParameters?:IMediaQueryParamters) => {
    const baseUrl = 'https://asset.lemansnet.com/';
    const queryKeys = queryParameters ?  Object.keys(queryParameters) : [];
    let queryString = '';

    if (queryParameters && queryKeys.length > 0) {
        queryString = queryKeys.reduce( (acc, key) => acc += `${key}=${queryParameters[key]}&`, '?');
    }

    if(imageData && imageData.mediaUrl) {
        return `${baseUrl}${imageData.prefixUrl}${imageData.mediaUrl}.${imageData.extension}${queryString}`;
    } else {
        return `${fallbackImage}${queryString}`;
    }    
}

export interface IMediaUrlData {
    extension: string;
    mediaUrl: string;
    prefixUrl: string;
}

interface IMediaQueryParamters {
    x?: number;
    y?: number;
    t?: 'image/png' | 'image/jpeg';
    o?: string;
    zc?: number;
}