import { FacebookOutlined, InstagramOutlined, TwitterOutlined, YoutubeOutlined } from '@ant-design/icons';
import * as React from 'react';
import { facebookUrl, instagramUrl, twitterUrl, youTubeUrl } from 'theme/libs/config';
import './SocialMedia.styles.less';
import { ISocialMediaProps } from "./SocialMedia.types";

export const SocialMedia = (props: ISocialMediaProps) =>
    <div className="SocialMedia">
        <ul className="social__list">
            {facebookUrl &&
                <li className="social__item social__item--facebook">
                    <a href={facebookUrl} target="_new"><FacebookOutlined /></a>
                </li>
            }
            {twitterUrl &&
                <li className="social__item social__item--twitter">
                    <a href={twitterUrl} target="_new"><TwitterOutlined /></a>
                </li>
            }
            {instagramUrl &&
                <li className="social__item social__item--instagram">
                    <a href={instagramUrl} target="_new"><InstagramOutlined /></a>
                </li>
            }
            {youTubeUrl &&
                <li className="social__item social__item--youtube">
                    <a href={youTubeUrl} target="_new"><YoutubeOutlined /></a>
                </li>
            }
        </ul>
    </div>;
