import request from 'superagent';

const restBase = '/wp-json/lemans/v1/';
const restUrl = (apiConfig:any, url:string) => {
    return `${apiConfig.host}${restBase}${url}`;
}

export const reCaptchaApi = (apiConfig:any) => ({
    get: (url:string) => request
        .get(restUrl(apiConfig, url))
        .set('Accept', 'application/json')
});