import { Col, Row } from 'antd';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import * as React from 'react';
import './SplitPage.styles.less';
import { ISplitPageProps } from "./SplitPage.types";

export const SplitPage = (props: ISplitPageProps) =>
    <div className="SplitPage">
        <Row>
            <Col lg={12} span={24}>
                <div className="split-page-content">
                    <h1>{props.title}</h1>
                    {props.children}
                </div>
            </Col>
            <Col lg={12} span={0}>
                <div className="split-page-image-wrapper">
                    <div
                        className="split-page-image"
                        style={{ backgroundImage: props.imageUrl ? `url(${apiConfig.host}${props.imageUrl})` : '' }}
                    />
                </div>
            </Col>
        </Row>
    </div>