import * as React from 'react';
import './PartSpecs.styles.less';
import {IPartSpecsProps} from "./PartSpecs.types";

export const PartSpecs = ({specs}:IPartSpecsProps) => <div className="partSpecs">
    <h2>Specs</h2>

    <table>
        {specs.map(spec => <tr key={spec.attributeName}>
            <th>{spec.attributeName}</th>
            <td>{spec.attributeValue}</td>
        </tr>)}
    </table>
</div>;
