import { useEffect, useState } from 'react';
import { categoryTemplate, navTemplate, partTemplate } from 'theme/libs/config';
import { memoize, memoizePromise } from 'ts-functional';
import { api } from '../api';
import { feedParameters } from '../FeedParameters';
import { ICategorySemantic, IMasterFeed, INavSemantic, IPartSemantic, ISemantic } from './masterFeed.types';

export const getFeed = memoize(<T, Q extends {}>(templateName:string) => memoizePromise((query:Q) => new Promise<ISemantic & T>((resolve, reject) => {
    feedParameters()
        .then(feedParams => api.get("lemans/api/feed-info/masterFeed")
            .query({
                ...feedParams,
                ...query,
                locale: "en",
                templateName,
            })
        ).then((data:IMasterFeed<T>) => {
            const updatedFeedData = (data.body || JSON.parse(data.text)).root[0].domain[0].semantic[0] as unknown as (ISemantic & T);
            if (updatedFeedData) {
                resolve(updatedFeedData);
            } else {
                reject(`Feed data for ${templateName} not found`);
            }
        });
}), {}), {});
   
export const useFeed = <T, Q extends {}>(templateName:string) =>
    (query:Q):[(ISemantic & T) | undefined, boolean] => {
        const [feedData, setFeedData] = useState<(ISemantic & T) | undefined>();
        const [loading, setLoading] = useState(false);

        useEffect(() => {
            setLoading(true);
            getFeed<T, Q>(templateName)(query)
                .then(setFeedData)
                .catch(() => {
                    console.log(`Feed data for ${templateName} not found`);
                })
                .finally(() => {setLoading(false);});
        }, [JSON.stringify(query)]);

        return [feedData, loading];
    }

export const useCategory = (categoryId:number) => useFeed<ICategorySemantic, {categoryId: number}>(categoryTemplate)({categoryId});
export const useCategoryNav = () => useFeed<INavSemantic, {}>(navTemplate)({});
export const usePart = (partNumber:string) => useFeed<IPartSemantic, {partNumber: string}>(partTemplate)({partNumber});
