import { StaticPageVideo } from 'lemans-brandsites-common/components/StaticPage/StaticPageVideo/StaticPageVideo.component';
import { IPage } from 'lemans-brandsites-common/libs/api.types';
import { page } from 'lemans-brandsites-common/libs/GetPageFromWP';
import React, { useEffect, useState } from 'react';
import { BannerImage } from '../BannerImage';
import { SponsorshipForm } from '../SponsorshipForm';
import './Sponsorship.styles.less';
import { ISponsorshipProps } from "./Sponsorship.types";

const parseDateString = (dateString:string) => {
    const values = dateString.split("/");
    const monthIndex = Number(values[0]) - 1;
    const day = Number(values[1]);
    const year = new Date().getFullYear();
    
    return new Date(year, monthIndex, day);
}

const dateIsValid = (startDate:string, endDate:string) => {
    let output = false;
    const today = new Date();

    if (endDate && startDate) {
        const start = parseDateString(startDate);
        const end = parseDateString(endDate);
        // Set years to the end and start dates; if the end date is before the start date, the start date is during last year
        end.setFullYear( today.getFullYear() );
        start.setFullYear( end.getMonth() < start.getMonth() ? today.getFullYear() - 1 : today.getFullYear() );

        output = today > start && today < end;
    }
    
    return output;
}

export const Sponsorship = (props:ISponsorshipProps) => {
    const [pageData, setPageData] = useState<IPage | null>(null);

    useEffect(() => {
        page("sponsorship").then(setPageData);
    }, []);

    const startDate:string = pageData?.acf?.start_date;
    const endDate:string = pageData?.acf?.end_date;
    const showForm = dateIsValid(startDate, endDate);

    const closedMessage:string = pageData?.acf?.closed_message;
    const openMessage:string = pageData?.acf?.open_message;

    const videoSource:string = pageData?.acf?.video_source;

    return <div className="sponsorship">
        <BannerImage {...pageData?.acf} />
        <p className="rsLink">
            <a href="https://ridersupport.mooseracing.com/" target="_blank">Click here</a> for the Moose Racing sponsored rider site.
        </p>

        {!showForm && <>
            <div className="message" dangerouslySetInnerHTML={{__html: closedMessage}} />
            {!!videoSource && <StaticPageVideo slug='sponsorship' />}
        </>}

        {showForm && <>
            <div className="message" dangerouslySetInnerHTML={{__html: openMessage}} />
            <SponsorshipForm />
        </>}
    </div>;
}
