import { Col, Row } from 'antd';
import { Chart } from 'lemans-brandsites-common/components/Chart/Chart.component';
import { ProductBrandImage } from 'lemans-brandsites-common/components/ProductBrandImage/ProductBrandImage.component';
import { ProductFeatures } from 'lemans-brandsites-common/components/ProductFeatures';
import { ProductGroupLinks } from 'lemans-brandsites-common/components/ProductGroupLinks/ProductGroupLinks.component';
import { ProductTitle } from 'lemans-brandsites-common/components/ProductTitle/ProductTitle.component';
import { RelatedEntityList } from 'lemans-brandsites-common/components/RelatedEntityList/RelatedEntityList.component';
import { IFeedProduct, IFeedProductGroup, IFeedProductRelatedEntity } from 'lemans-brandsites-common/libs/ProductFeed/masterFeed.types';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { prodUrl } from 'theme/libs/util';
import { first } from 'ts-functional';
import { Breadcrumbs } from '../Breadcrumbs';
import { MediaSwitcher } from '../MediaSwitcher';
import './ProductApparel.styles.less';
import { IProductApparelProps } from "./ProductApparel.types";

const prodTypes = ["HELMET", "GLOVE", "GOGGLE", "PANT", "JERSEY", "BOOT"];

const getType = (name:string) => prodTypes.filter(t => name.toLocaleUpperCase().includes(t))[0] || "NO TYPE";
const isType = (name:string, relatedName:string) => getType(name) === getType(relatedName) && getType(name) !== "NO TYPE";

const isMatch = (product:IFeedProduct, productGroup:IFeedProductGroup, relatedProduct:IFeedProductRelatedEntity) => 
    isType(product.productName          , relatedProduct.relatedName) ||
    isType(productGroup.productGroupName, relatedProduct.relatedName);

const getRelated   = (product:IFeedProduct, productGroup:IFeedProductGroup) => (productGroup.relatedEntity || []).filter(r => !isMatch(product, productGroup, r));
const getColorways = (product:IFeedProduct, productGroup:IFeedProductGroup) => (productGroup.relatedEntity || []).filter(r =>  isMatch(product, productGroup, r));

const titleProps = {
    getName: (product:IFeedProduct, productGroup:IFeedProductGroup) => productGroup.productGroupName.split("-")[0].trim(),
    getSubName: (product:IFeedProduct, productGroup:IFeedProductGroup) => productGroup.productGroupName.split("-")[1].trim(),
}



export const ProductApparel = ({product, productGroup}:IProductApparelProps) => !!productGroup ? <>
    <Row>
        <Col xs={24} lg={8}>
            <MediaSwitcher media={productGroup.media} noVideo/>
        </Col>
        <Col lg={2}/>
        <Col xs={24} lg={14}>
            <ProductBrandImage product={product} />
            <Breadcrumbs
                crumbs={product.breadcrumb}
                extra={[{
                    text: `${product.productName} - ${productGroup.productGroupName}`,
                    url: prodUrl(product.productId, product.productGroupId),
                }]}
            />
            <ProductTitle product={product} productGroup={productGroup} {...titleProps} />
            <ProductFeatures data={product.features} />
            <Link className="sizeChartLink" to="/size-charts">Size Information</Link>
            <ProductGroupLinks title="Related Products" groups={product.productGroups || []} currentGroupId={productGroup.productGroupId}/>
        </Col>
        <Col xs={24}>
            <br />
            <Chart title="Part Numbers" chart={first(productGroup.chart || [])} partNumberLabel='Part' product={product}/>
            <MediaSwitcher media={productGroup.media} noImages inline />
            <RelatedEntityList entities={getRelated(product, productGroup)} title='Complete the Look' />
            <RelatedEntityList entities={getColorways(product, productGroup)} title='Additional Colors' />
        </Col>
    </Row>
</> : null;