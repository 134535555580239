import { ContactForm } from 'lemans-brandsites-common/components/ContactForm';
import { Page } from 'lemans-brandsites-common/components/Page';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { SplitPage } from 'lemans-brandsites-common/components/SplitPage';
import { pageRestHandleSideEffects } from 'lemans-brandsites-common/libs/apiHandleSideEffects';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { brandName, contactFormKey } from 'theme/libs/config';
import { FourOhFour } from '../FourOhFour';
import './Contact.styles.less';
import { IContactCustomFields, IContactProps } from "./Contact.types";

export const Contact = (props: IContactProps) => {
    // const [content, setContent] = useState<string>('');
    const [customFields, setCustomFields] = useState<IContactCustomFields>();
    const [fourOhFour, isLoading, data] = pageRestHandleSideEffects('contact');
    const [title, setTitle] = useState<string>('');

    useEffect(() => {
        console.log('data updated: ', data);
        // setContent(data?.content.rendered ? data?.content.rendered : '');
        setCustomFields(data?.acf);
        setTitle(data?.title.rendered ? data?.title.rendered : '');
    }, [data]);

    return <>
        {fourOhFour && <FourOhFour />}
        {isLoading && <Spinner defaultIndicator={true} size="large" />}
        {!isLoading && !fourOhFour && data &&
            <Page className="About" title={`${brandName} - ${title}`}>
                <SplitPage
                    imageUrl={customFields?.side_banner}
                    title={title}
                >
                    {/* <div dangerouslySetInnerHTML={{ __html: content }} /> */}
                    <ContactForm
                        domain={11}
                        formKey={contactFormKey}
                    />
                </SplitPage>
            </Page>
        }
    </>
}
