import { memoizePromise } from 'ts-functional';
import { api } from '../api';

let params:ILfrParams;
export const feedParameters = memoizePromise(async ():Promise<ILfrParams> => {
    if(!params) {
        const res = await api.get(`lemans/lfr/settings`);
        params = res.body as ILfrParams;
    }
    return params;
}, {});

interface ILfrParams {
    domainId: number;
    catalogInstanceId: number;
}