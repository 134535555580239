import { api } from '../api';

let params:ISolrParams;
export const solrParameters = async ():Promise<ISolrParams> => {
    // Memoize this so it's only called once.
    if(!params) {
        const res = await api.get(`lemans/lss/settings`);
        params = res.body as ISolrParams;
    }
    return params;
}

interface ISolrParams {
    domainId: number;
    catalogInstanceId: number;
}