import * as React from "react";
import { IFeatureListProps } from './FeatureList.types';

export const FeatureList = (props: IFeatureListProps) => 
    <ul className={`product-features-bullet--ul`}>
        { props.data.map( item => 
            <li key={item.sequence}>
                {item.feature}
            </li>
        )}
    </ul>;