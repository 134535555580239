import { IMediaUrlData } from 'lemans-brandsites-common/libs/imageUrl';
import { IEntityMedia, IFeedProduct } from 'lemans-brandsites-common/libs/ProductFeed/masterFeed.types';
import * as React from 'react';
import { imageUrl } from 'theme/libs/util';
import { first } from 'ts-functional';
import './ProductBrandImage.styles.less';
import { IProductBrandImageProps } from "./ProductBrandImage.types";

const getMediaData = (product:IFeedProduct):IMediaUrlData => ({
    extension: product.brandExtension,
    mediaUrl: product.brandMediaUrl,
    prefixUrl: product.brandPrefixUrl,
});

export const ProductBrandImage = ({product}:IProductBrandImageProps) => {
    const brandImage:IEntityMedia | undefined = first((product.media || []).filter(img => img.tag.includes("BRAND_LOGO")));

    const mediaData:IMediaUrlData = !!brandImage ? brandImage : getMediaData(product);
    
    if(mediaData && mediaData.mediaUrl) {
        return <img src={imageUrl(mediaData)} />;
    } else {
        return <></>;
    }
}
