import { extractTreeData } from "lemans-brandsites-common/libs/extract";
import { IFeedBreadcrumb, IFeedCategory, IFeedProduct, IFeedProductGroup } from "lemans-brandsites-common/libs/ProductFeed/masterFeed.types";
import { intersection, last } from "lodash";
import { pipe, prop } from "ts-functional";
import { ProductApparel } from "../ProductApparel";
import { ProductHardParts } from "../ProductHardParts";

export default interface IProductTemplateInputProps {
    product: IFeedProduct;
    productGroup: IFeedProductGroup;
}

// Define which tags to to which product templates
// Defaults to hard parts template if the product has no tag
const templates:{[name:string]: React.FC<IProductTemplateInputProps>} = {
    APPAREL:  ProductApparel,
    HARDPART: ProductHardParts,
    default:  ProductHardParts,
};

const extractTag = (tagList:string) => intersection(tagList.split(";"), Object.keys(templates))[0] || "";

const toString = (n:number) => `${n}`;

// Get the template tag for a product.  This allows the user to setup default templates on a category
// level so that they don't need to be set for individually set for lower level categories or products.
export const getTag = (productTag: string, categories:IFeedCategory[], breadCrumbs: IFeedBreadcrumb[]) => {
    // Extract all of the tags from the category nav hierarcy and index them by categoryId
    type PartCategory = Pick<IFeedCategory, "categoryId" | "tag" | "categories">;
    const categoryTags = extractTreeData<PartCategory, string>(
        pipe(prop("categoryId"), toString),
        prop("tag"),
        (cat:PartCategory) => cat.categories || [],
    )({categoryId: 0, tag: "", categories}, true);

    // Get the category tags that correspond to the product's breadcrumb categories
    const parentTags = breadCrumbs
        .map(prop("categoryId"))
        .map(id => categoryTags[id])
        .filter(tag => !!tag);

    // Add the product's tag onto the end of the category tags, and extract the template tag for them all
    const allTags = [...parentTags, productTag].map(extractTag).filter(t => !!t);
    console.log(allTags);

    // Return the last tag:  The product tag overrides the category tags, which override parent category tags
    return last(allTags) || "default";
}

export const getTemplate = (tag?:string):React.FC<IProductTemplateInputProps> => !!tag
    ? templates[tag]
    : templates.default;

