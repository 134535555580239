import { FilePdfTwoTone } from '@ant-design/icons';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { partUrl } from 'theme/libs/routes';
import { imageUrl } from 'theme/libs/util';
import './Chart.styles.less';
import { IChartProps } from "./Chart.types";

export const Chart = (props: IChartProps) => {
    const headers = props.chart?.header[0]?.columns || [];
    const data = props.chart?.rows;

    const getInstallationInstructionParts = () => {
        const res = (props.product?.parts || []).map((parts:any) => (parts.media || []).find((med:any) =>  med.viewingAngle.toLowerCase() === "manual" && med.extension === "pdf"));
        // Object.keys(res).forEach(key => res[key] === undefined ? delete res[key] : {}) ;
        Object.keys(res).forEach(key => res[key] === undefined && delete res[key]) ;

        // use Boolean as callback for truthy values - remove undefined from the array of objects
        return res.filter(Boolean);
    }

    const installationInstructionParts = getInstallationInstructionParts();

    return Array.isArray(props.chart?.header)
        ? <div className="Chart" key="chartTableContainer">
            <h2>{props.title}</h2>
            <table key="chartTable">
                <tbody key="chartTableBody">
                    <tr>
                        <th key="header-col-partNumber">{props.partNumberLabel || "Part Number"}</th>
                        {headers.map((header, i) => <th key={`header-col-${i}`}>{header.name}</th>)}
                        {installationInstructionParts.length > 0 &&
                            <th>INSTRUCTIONS</th>
                        }
                    </tr>
                    {(data || []).map((row, rowI) => <tr key={`data-row-${rowI}`}>
                        <>
                        <td>
                            <Link to={partUrl(row.partNumber)}>{row.partNumber}</Link>
                        </td>
                        {headers.map((header, headerI) => <td key={`data-col-${headerI}`}>
                            {row.columns[headerI].value}
                        </td>)}
                        {installationInstructionParts.length > 0 &&
                        <td>
                            {installationInstructionParts.map(partMedia => {
                                if(partMedia.entityId === row.partNumber.replace("-", "")) {
                                    const docSrc = {
                                        extension: partMedia.extension,
                                        mediaUrl: partMedia.mediaUrl,
                                        prefixUrl: partMedia.prefixUrl,
                                    };

                                    return <a
                                        key={`data-col-${partMedia.mediaUrl}`}
                                        href={imageUrl(docSrc)}
                                        target={'_blank'}
                                        title={'Click again if download did not automatically start.'}
                                    >
                                        <FilePdfTwoTone twoToneColor="#ff0000" />
                                    </a>
                                } else {
                                    return <></>
                                }
                            })}
                        </td>}
                        </>
                    </tr>)}
                </tbody>
            </table>
        </div>
        : <></>;
}