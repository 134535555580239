import { FilePdfOutlined, FileTextOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { useCustomFields } from 'lemans-brandsites-common/libs/useCustomFields';
import * as React from 'react';
import { BannerImage } from '../BannerImage';
import './CatalogPage.styles.less';
import { ICatalogData, ICatalogPageProps } from "./CatalogPage.types";

export const CatalogPage = (props:ICatalogPageProps) => {
    const {data, isLoading} = useCustomFields<ICatalogData>("catalog");

    return <Spinner isLoading={isLoading}>
        {data && <div>
            <BannerImage {...data} />
            <div className="catalogList">
                {data.catalogs.map((cat, i) => <div key={i} className="catalogItem">
                    <img src={cat.thumbnail} className="catalogThumb" />
                    <p className="catalogText">{cat.marketing_text}</p>
                    <a className="catalogLink" href={cat.pdf_link}>
                        <Typography.Text type="danger"><FilePdfOutlined /></Typography.Text><br/>
                        Download PDF ({cat.pdf_size} MB)
                    </a>
                    <a className="catalogLink" href={cat.flipper_link}>
                        <FileTextOutlined /><br/>
                        View digital version
                    </a>
                </div>)}
            </div>
        </div>}
    </Spinner>;
}
