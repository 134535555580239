import { imageUrl as baseImageUrl } from "lemans-brandsites-common/libs/imageUrl";

export const imageUrl = baseImageUrl('/no_image_available.jpg');

export const catUrl = (id:number) => `/category/${id}`;

export const prodUrl = (productId:number | string, productGroupId?:number | string) => !!productGroupId
    ? `/product/${productId}/productGroup/${productGroupId}`
    : `/product/${productId}`;

export const productGroupUrl = (productGroupId: number | string) => `/productGroup/${productGroupId}`;