import { Col, Row } from 'antd';
import { Chart } from 'lemans-brandsites-common/components/Chart/Chart.component';
import { ProductBrandImage } from 'lemans-brandsites-common/components/ProductBrandImage/ProductBrandImage.component';
import { ProductFeatures } from 'lemans-brandsites-common/components/ProductFeatures';
import { ProductFitment } from 'lemans-brandsites-common/components/ProductFitment/ProductFitment.component';
import { ProductGroupLinks } from 'lemans-brandsites-common/components/ProductGroupLinks/ProductGroupLinks.component';
import { ProductTitle } from 'lemans-brandsites-common/components/ProductTitle/ProductTitle.component';
import { IFeedProduct, IFeedProductGroup } from 'lemans-brandsites-common/libs/ProductFeed/masterFeed.types';
import * as React from 'react';
import { prodUrl } from 'theme/libs/util';
import { first } from 'ts-functional';
import { Breadcrumbs } from '../Breadcrumbs';
import { MediaSwitcher } from '../MediaSwitcher';
import './ProductHardParts.styles.less';
import { IProductHardPartsProps } from "./ProductHardParts.types";

const titleProps = {
    getName: (product:IFeedProduct, productGroup:IFeedProductGroup) => productGroup.productGroupName.split("-")[0].trim(),
    getSubName: (product:IFeedProduct, productGroup:IFeedProductGroup) => (productGroup.productGroupName.split("-")[1] || "").trim(),
}

export const ProductHardParts = ({product, productGroup}:IProductHardPartsProps) => !!productGroup ? <>
    <Row>
        <Col xs={24} lg={8}>
            <MediaSwitcher media={productGroup.media} noVideo/>
        </Col>
        <Col lg={2}/>
        <Col xs={24} lg={14}>
            <ProductBrandImage product={product} />
            <Breadcrumbs crumbs={product.breadcrumb} extra={[{url: prodUrl(product.productId, productGroup.productGroupId), text: product.productName}]} />
            <ProductTitle product={product} productGroup={productGroup} {...titleProps} />
            <ProductFeatures data={product.features} />
            <ProductGroupLinks title="Related Products" groups={product.productGroups || []} currentGroupId={productGroup.productGroupId}/>
        </Col>
        <Col xs={24}>
            <br />
            <Chart title="Part Numbers" chart={first(productGroup.chart || [])} product={product}/>
            <MediaSwitcher media={productGroup.media} noImages inline />
            <ProductFitment fitments={productGroup.fitments} />
        </Col>
    </Row>
</> : null;