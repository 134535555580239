
import { Col, Row } from 'antd';
import { Spinner } from 'lemans-brandsites-common/components/Spinner';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import { onlineDealer } from 'lemans-brandsites-common/libs/DealerLocatorApi';
import * as React from "react";
import { FourOhFour } from '../FourOhFour';
import { OnlineDealerItem } from '../OnlineDealerItem';
import './OnlineDealers.styles.less';
import { OnlineDealersProps } from "./OnlineDealers.types";

export const OnlineDealers = (_:OnlineDealersProps) => {
    const [onlineDealerData, setOnlineDealerData] = React.useState<any[] | undefined>(undefined);

    const [displayFourOhFour, setDisplayFourOhFour] = React.useState(false);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        onSearch();
    }, []);

    const onSearch = async () => {
        setIsLoading(true);

        try {
            const data = await onlineDealer(apiConfig);

            if (!data) {
                setDisplayFourOhFour(true);
            }

            if (!Array.isArray(data)) {
                throw new Error('An error was returned from the Dealer Locator Service.');
            }           

            setOnlineDealerData(data);
        }
        catch {
            setDisplayFourOhFour(true);
        }
        finally {
            setIsLoading(false);
        }
    }

    return (<>
        {displayFourOhFour && <FourOhFour />}
        {onlineDealerData &&
        <>
            {isLoading && !onlineDealerData && <Spinner defaultIndicator={true} />}
            {!isLoading &&
                <div className={'online-dealers-wrapper--div'} >
                    <h1>MOOSE RACING ONLINE DEALERS</h1>
                    <Row gutter={16}>
                    { Array.isArray(onlineDealerData) && 
                        onlineDealerData.filter( dealer => dealer.dealerWebSiteUrl).map( dealer => 
                        <Col key={dealer.dealerName} xs={12} md={6}>
                            <OnlineDealerItem data={dealer} />
                        </Col>
                    )} 
                    </Row>
                </div>
            }
        </>
        }
    </>);
}