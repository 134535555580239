import { Col, Row } from 'antd';
import { Page } from 'lemans-brandsites-common/components/Page';
import { SpinnerCss } from 'lemans-brandsites-common/components/SpinnerCss';
import { apiRestHandleSideEffects } from 'lemans-brandsites-common/libs/apiHandleSideEffects';
import * as React from 'react';
import { brandName } from 'theme/libs/config';
import { FourOhFour } from '../FourOhFour';
import './BikeDetails.styles.less';
import { IBikeDetailsProps } from "./BikeDetails.types";

export const BikeDetails = (props:IBikeDetailsProps) => {
    const [displayFourOhFour, isLoading, data] = apiRestHandleSideEffects(`wp/v2/mr_bike?slug=${props.slug}`);
    const bikeDetails = data ? data[0]?.acf : {};

    return (
        <>
            { displayFourOhFour && <FourOhFour />}
            { isLoading && <SpinnerCss />}
            { !isLoading && 
                <Page
                    className="Bike-build-details"
                    description={`${brandName} - Bike Build Details`}
                    robots={{ follow: true, index: true }}
                    title={`${brandName} - Bike Build Details`}
                >                    
                    <div className='bikeDetailsContainer'>
                        {bikeDetails && bikeDetails !== undefined && Object.keys(bikeDetails).length > 0 && 
                        <>
                            <div className='firstRow'>
                                <Row gutter={8}>
                                    <Col xs={24} md={8} lg={8}>
                                        <>
                                            <img src={bikeDetails.bike_build_logo} /> <br />
                                            <div className='bikeDescr' dangerouslySetInnerHTML={{__html:bikeDetails?.bike_description}}/>
                                        </>
                                    </Col>
                                    <Col xs={24} md={16} lg={16}>
                                        <>
                                            <img className='primaryImage' src={bikeDetails.primary_image} />
                                        </>
                                    </Col>
                                </Row>
                            </div>
                            <br />
                            <Row>
                                {bikeDetails.bike_tile_images && bikeDetails.bike_tile_images.map((tile: any, i:number) => 
                                    <Col xs={24} md={12} lg={12} key={i}>
                                        <img className='tile' src={tile.tile_image} /> 
                                    </Col>
                                )}
                            </Row>
                            <Row>
                                {bikeDetails.bike_video_url && 
                                    <Col xs={24}>
                                        <div className="video-wrapper">
                                        <iframe
                                            allowFullScreen
                                            className="video"
                                            frameBorder={0}
                                            src={`https://www.youtube.com/embed/${bikeDetails.bike_video_url}`}
                                            allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                                            width="560" height="315"
                                        />
                                        </div>
                                    </Col>
                                }
                            </Row>
                            <br />
                            <Row className='buildList'>
                                {bikeDetails.bike_build_list && bikeDetails.bike_build_list.length > 0 &&
                                    <Col xs={24}>
                                        <span className="buildListTitle">
                                            BUILD LIST
                                        </span>
                                    </Col>
                                }
                                {bikeDetails.bike_build_list && bikeDetails.bike_build_list.length > 0 && bikeDetails.bike_build_list.map((list:any) => { 
                                        const partNumb = list.part_number.replace(/-/g, '');
                                        return <>
                                        <Col xs={12} md={6}>
                                            {list.part_name}
                                        </Col>
                                        <Col xs={12} md={6}>
                                            <a href={`/part/${partNumb}`}>{list.part_number}</a>
                                        </Col>
                                        </>
                                    })
                                }
                            </Row>
                            <br />
                            <Row>
                                <Col xs={24}>
                                    <img className='bottomImg' src={bikeDetails.bike_closeup_image} /> 
                                </Col>
                            </Row>
                        </>
                        }
                    </div>
                </Page>
            }
        </>
    );
}