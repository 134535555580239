import { Page } from "lemans-brandsites-common/components/Page";
import { Spinner } from "lemans-brandsites-common/components/Spinner";
import { IPage } from "lemans-brandsites-common/libs/api.types";
import { page } from "lemans-brandsites-common/libs/GetPageFromWP";
import * as React from "react";
import { BannerImage } from "../BannerImage";
import { HelpCenterForm } from "../HelpCenterForm";
import './HelpCenter.styles.less';
import { IHelpCenterProps } from "./HelpCenter.types";

export const HelpCenter = (props:IHelpCenterProps) => {
    const [isLoading, setIsLoading] = React.useState(true);
    const [pageData, setPageData] = React.useState<IPage | undefined>(undefined);

    const getData = async () => {
        setIsLoading(true);
        try {
            const pageRes = await page('help-center');
            setPageData(pageRes);
        }
        catch (err) {
            // Do something?
        }
        finally {
            setIsLoading(false);
        }
    }

    React.useEffect( () => {
        getData()
    }, [])

    return (
        <>
        { isLoading &&
            <Spinner />
        }
        { !isLoading && 
            <Page
                description={'Moose Racing Help Center - answer your questions here.'}
                robots={{follow: true, index: true}}
                title={'Moose Racing - Help Center'}
            >
                <BannerImage {...pageData?.acf} />
                <div className={'help-center-wrapper--div'}>
                    <h2>Send Us A Message</h2>
                    <HelpCenterForm />
                    <br />
                    <h2>Frequently Asked Questions</h2>
                    <div className={'help-center-faqs--div'}>
                        { pageData && pageData.acf?.faqs.map( (faq:any, index:any) => 
                            <div className={'help-center-faq--item'} key={index}>
                                <h3>{faq.question}</h3>
                                <div dangerouslySetInnerHTML={{__html: faq.answer}} />
                            </div>
                        )}
                    </div>
                </div>
            </Page>
        }
        </>
    );
}
    