import { useEffect, useState } from "react";
import { prodTemplate } from "theme/libs/config";
import { api } from "../api";
import { feedParameters } from "../FeedParameters";
import { IProductSemantic, ISemantic, ProductFeed } from "./masterFeed.types";

export const useProductFeed = <T, Q extends {}>(query: Q, semantic:number = 0): [(ISemantic & T) | undefined, boolean] => {
    const [feedData, setFeedData] = useState<(ISemantic & T) | undefined>();
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        setLoading(true);
        setFeedData(undefined);
        feedParameters().then(feedParams => {
            api.get("lemans/api/feed-info/masterFeed").query({
                ...feedParams,
                ...query,
                locale: "en",
                templateName: prodTemplate,
            }).then((data: ProductFeed) => {
                const updatedFeedData = data.body.root[0].domain[0].semantic[semantic] as unknown as (ISemantic & T);
                if (updatedFeedData) {
                    setFeedData(updatedFeedData);
                } else {
                    console.log('ProductFeed data not found');
                }
            }).finally(() => { setLoading(false); });
        });
    }, [JSON.stringify(query)]);

    return [feedData, loading];
}
export const useProduct = (productId: number, semantic:number = 0) =>
    useProductFeed<IProductSemantic, { productId: number }>({ productId }, semantic);

export const useProductGroup = (productGroupId: number, semantic:number = 0) =>
    useProductFeed<IProductSemantic, { productGroupId: number }>({ productGroupId }, semantic);
