import { FacebookOutlined, InstagramOutlined, TwitterOutlined, YoutubeOutlined } from '@ant-design/icons';
import { Col, Row } from 'antd';
import { Page } from 'lemans-brandsites-common/components/Page';
import { SpinnerCss } from 'lemans-brandsites-common/components/SpinnerCss';
import { api } from 'lemans-brandsites-common/libs/api';
import { pageRestHandleSideEffects } from 'lemans-brandsites-common/libs/apiHandleSideEffects';
import * as React from 'react';
import { brandName } from 'theme/libs/config';
import { FourOhFour } from '../FourOhFour';
import './Team.styles.less';
import { IPageBanner, ITeamProps } from "./Team.types";

export const Team = (props:ITeamProps) => {
    const [displayFourOhFour, isLoading, data] = pageRestHandleSideEffects('team');
    const [loading, setLoading] = React.useState(true);
    const [pageBanner, setPageBanner] = React.useState<IPageBanner>();
    const [atheletesList, setAtheletesList] = React.useState([]);
    const teamID = props.type === 'bike' ? 7 : 8;

    React.useEffect(() => {
        setLoading(true);
        Promise.all([
            api.get(`wp/v2/mr_team`).query({team: teamID, per_page: '100'}),
        ]).then(([teamListResponse]) => {
            setAtheletesList(
                teamListResponse.body
                    .filter((res:any) => res.acf.featured_rider === true)
                    .sort( (a:any, b:any) => a.acf.last_name.localeCompare(b.acf.last_name))
            );
        }).catch((err: any) => {
            console.log(err);
        })
        .finally(() => {
            setLoading(false);
        })
    }, []);

    React.useEffect(() => {
        setPageBanner(data?.acf as IPageBanner);
    }, [data]);

    return (
        <>
            { displayFourOhFour && <FourOhFour />}
            { isLoading || loading && <SpinnerCss />}
            { !isLoading && !loading &&
                <Page
                    className="Team"
                    description={`${brandName} - Team`}
                    robots={{ follow: true, index: true }}
                    title={`${brandName} - Team`}
                >
                    {pageBanner &&
                        <div className='team_banner'>
                            {props.type === 'bike'
                            ? <><img src={pageBanner.bike_banner} className="bannerDesktop" /> {!!pageBanner.bike_banner_mobile && <img className="bannerMobile" src={pageBanner.bike_banner_mobile} />}</>
                            : <><img src={pageBanner.atv_banner} className="bannerDesktop" /> {!!pageBanner.atv_banner_mobile && <img className="bannerMobile" src={pageBanner.atv_banner_mobile} />}</>
                            }
                        </div>
                    }
                    
                    <div className='teamMemberBox'>
                        <Row gutter={[12,16]}>
                        {atheletesList && atheletesList !== undefined && atheletesList?.map((list: any, i) => 
                            <Col key={i} xs={24} md={12} lg={8}>
                                <>
                                    <img src={`${list.acf.landing_page_image}`} key={list.id}/> <br />
                                    <span className='title'>{`${list.acf.first_name} ${list.acf.last_name}`}</span><br />
                                    {list.acf.series_list && list.acf.series_list.map((series:any, ind:number) => 
                                            <span key={ind}>{ind > 0 ? ', ' : ''}{series.series}</span> 
                                        )
                                    }
                                    <br/><br/>
                                    <strong>RESIDENCE:</strong> {list.acf.hometown}{list.acf.hometown? ', ': ''}{list.acf.state} <br />
                                    <strong>YEARS PRO:</strong> {list.acf.years_pro} <br />
                                    <strong>NUMBER OF SERIES WINS:</strong> {list.acf.no_of_series_wins} <br />

                                    <span className='social_icons social_icon_fb'>{list.acf.rider_facebook_url ? <a href={list.acf.rider_facebook_url} target="_blank"><FacebookOutlined />  </a> :''}</span>
                                    <span className='social_icons social_icon_insta'>{list.acf.rider_instagram_url ? <a href={list.acf.rider_instagram_url} target="_blank"><InstagramOutlined />  </a> :''}</span>
                                    <span className='social_icons social_icon_twitter'>{list.acf.rider_twitter_url ? <a href={list.acf.rider_twitter_url} target="_blank"><TwitterOutlined />  </a> :''}</span>
                                    <span className='social_icons social_icon_yt'>{list.acf.rider_youtube_url ? <a href={list.acf.rider_youtube_url} target="_blank"><YoutubeOutlined />  </a> :''}</span>
                                </>
                            </Col>
                        )}
                        </Row>
                    </div>
                </Page>
            }
        </>
    );
}