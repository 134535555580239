import { CloseOutlined, MenuOutlined } from '@ant-design/icons';
import clsx from 'clsx';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { injectNav } from 'theme/libs/useNav';
import { catUrl } from 'theme/libs/util';
import { inject, mergeProps } from 'unstateless';
import { QuickSearch } from '../QuickSearch';
import './NavMobile.styles.less';
import { INavMobileInputProps, NavMobileProps } from "./NavMobile.types";

const connect = inject<INavMobileInputProps, NavMobileProps>(mergeProps(
    injectNav,
));

export const NavMobile = connect(({categories, menu, updateCat, isActive, close}:NavMobileProps) =>
    <header className="header-mobile">
        <Link to="/"><img src="/Moose-Logo.jpg" className="logo" alt="Logo" /></Link>
        {!menu.isOn && <MenuOutlined  onClick={menu.on } />}
        { menu.isOn && <CloseOutlined onClick={menu.off} />}
        {menu.isOn && <ul className="mobile-nav">
            {categories.map(topCat => <li
                key={topCat.categoryId}
                onClick={updateCat(topCat)}
            >
                <div>{topCat.categoryName}</div>
                <ul className={clsx(["sub-cat", isActive(topCat) && "open"])}>
                    {(topCat.categories ||[]).map(subCat =>
                        <li key={subCat.categoryId} onClick={close}>
                            <Link to={catUrl(subCat.categoryId)}>{subCat.categoryName}</Link>
                        </li>
                    )}
                </ul>
            </li>)}
            <li onClick={close}><Link to="/sponsorship">Sponsorship</Link></li>
            {/* <li onClick={close}><Link to="/news">Blog</Link></li> */}
            <li onClick={close}><Link to="/dealer-search">Dealer Locator</Link></li>
            <li><QuickSearch inline onSearch={close}/></li>
        </ul>}
    </header>
);