import * as React from 'react';
import './StaticPage.styles.less';
import { IStaticPageProps } from "./StaticPage.types";
import { StaticPageBanner } from './StaticPageBanner/StaticPageBanner.component';
import { StaticPageContent } from './StaticPageContent/StaticPageContent.component';
import { StaticPageVideo } from './StaticPageVideo/StaticPageVideo.component';

export const StaticPage = ({slug}:IStaticPageProps) => 
    <div className="staticPage">
        <StaticPageBanner slug={slug} />
        <StaticPageVideo slug={slug} />
        <StaticPageContent slug={slug} />
    </div>;
