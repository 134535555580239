import { switchOn } from "atp-pointfree";
import { localApiUrl, localImageBase } from "theme/libs/config";
import { getConfig } from './GetConfig';
import { getEnvironment } from './GetEnvironment';

const e = getEnvironment(window.location.hostname);

export const apiConfig = getConfig(e, {    
    host: env => switchOn(env.env, {        
        default: () => localApiUrl,
        prod:    () => '',
        ready:   () => '', 
        stage:   () => '',
    }),
    restBase: "/wp-json/",
});

export const siteConfig = getConfig(e, {
    imageBase: env => switchOn(env.env, {
        default: () => localImageBase,
        prod:    () => '',
        ready:   () => '',
        stage:   () => '',
    })
});