import { Func, Index } from 'ts-functional/dist/types';
import { merge } from './merge';

export const extract = (keys:string[]) =>
    (obj:any) => keys.reduce(
        (combined:any, key:string) => merge(combined, {[key]: obj[key]}),
        {}
    );

export const extractTreeData = <T, P>(
    getId:Func<T, string>,
    getProperty:Func<T, P>,
    getChildren: Func<T, T[]>
):(obj:T, skipTop?:boolean) => Index<P> =>
    (obj:T, skipTop?:boolean):Index<P> => ({
        ...(skipTop ? {} : {[getId(obj)]: getProperty(obj)}),
        ...getChildren(obj).reduce(
            (combined:Index<P>, child:T):Index<P> => ({
                ...combined,
                ...extractTreeData(getId, getProperty, getChildren)(child, false),
            }),
            {}
        )
    });
