import { IPage } from 'lemans-brandsites-common/libs/api.types';
import { page } from 'lemans-brandsites-common/libs/GetPageFromWP';
import * as React from 'react';
import './StaticPageBanner.styles.less';
import { IStaticPageBannerProps } from "./StaticPageBanner.types";

export const StaticPageBanner = ({slug}:IStaticPageBannerProps) => {
    const [data, setData] = React.useState<IPage | null>(null);

    React.useEffect(() => {
        page(slug).then(setData);
    }, [slug]);

    console.log(data);
    const banner:string | undefined = data?.acf?.banner_image;
    const overlay:string | undefined = data?.acf?.banner_image_overlay;
    
    return <div className="staticPageBannerContainer">
        {!!banner && <img className="banner" src={banner} />}
        {!!overlay && <img className="bannerOverlay" src={overlay} />}
    </div>;
}
