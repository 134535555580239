import * as React from 'react';
import { Link } from 'react-router-dom';
import 'theme/components/Breadcrumbs/Breadcrumbs.styles.less';
import { IBreadcrumbsProps } from "./Breadcrumbs.types";

export const Breadcrumbs = ({crumbs, getUrl, extra}:IBreadcrumbsProps) => {
    return <p className="breadcrumbs">
        {crumbs.map(crumb => <Link to={getUrl(crumb.categoryId)} key={crumb.categoryId}>
            {crumb.categoryName}
        </Link>)}
        {(extra || []).map(crumb => <Link to={crumb.url} key={crumb.url}>
            {crumb.text}
        </Link>)}
    </p>;
}
