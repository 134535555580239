import { IFeedCategory } from 'lemans-brandsites-common/libs/ProductFeed/masterFeed.types';
import { useCategoryNav } from 'lemans-brandsites-common/libs/ProductFeed/useFeed';
import { useToggle } from 'lemans-brandsites-common/libs/useToggle';
import { useState } from 'react';
import { createInjector } from 'unstateless';

export interface INavProps {
    menu: ReturnType<typeof useToggle>;
    activeCat: IFeedCategory | null;
    updateCat: (cat:IFeedCategory) => (e:any) => void;
    close: () => void;
    isActive: (cat:IFeedCategory) => boolean;
    categories: IFeedCategory[];
}

export const injectNav = createInjector(():INavProps => {
    // Whether the mobile menu is open
    const menu = useToggle();

    // Which is the actively selected category
    const [activeCat, setActiveCat] = useState<IFeedCategory | null>(null);
    const updateCat = (cat:IFeedCategory) => (e:any) => {
      e.stopPropagation();
      e.preventDefault();
      setActiveCat(cat.categoryId === activeCat?.categoryId ? null : cat);
    } 

    // Close the menu
    const close = () => {
        menu.off();
        setActiveCat(null);
    }
  
    // Which category is currently selected
    const isActive = (cat:IFeedCategory) => !!activeCat && cat.categoryId === activeCat.categoryId;
  
    // Fetch the nav from the feed
    const nav = useCategoryNav();
    const categories = nav[0]?.catalogs[0].categories.filter(c => !!c.categories).slice(0, 5) || [];

    return {menu, activeCat, updateCat, close, isActive, categories};
});