import { IPage } from 'lemans-brandsites-common/libs/api.types';
import { page } from 'lemans-brandsites-common/libs/GetPageFromWP';
import * as React from 'react';
import './StaticPageContent.styles.less';
import { IStaticPageContentProps } from "./StaticPageContent.types";

export const StaticPageContent = ({slug}:IStaticPageContentProps) => {
    const [data, setData] = React.useState<IPage | null>(null);

    React.useEffect(() => {
        page(slug).then(setData);
    }, [slug]);

    const content = data?.content.rendered;

    return <div className="staticContent" dangerouslySetInnerHTML={{__html: content || ""}}/>;
}
