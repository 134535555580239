// import { ShopOutlined, } from '@ant-design/icons';
import { Col, Row } from 'antd';
import clsx from 'clsx';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { injectNav } from 'theme/libs/useNav';
import { catUrl } from 'theme/libs/util';
import { inject, mergeProps } from 'unstateless';
import { QuickSearch } from '../QuickSearch';
import './NavDesktop.styles.less';
import { INavDesktopInputProps, NavDesktopProps } from "./NavDesktop.types";

const connect = inject<INavDesktopInputProps, NavDesktopProps>(mergeProps(
    injectNav,
));

export const NavDesktop = connect(({categories, updateCat, isActive, close, activeCat}:NavDesktopProps) => 
    <header className="header-desktop">
        <nav className="nav">
            <Row justify="space-around" align="middle" id="main">
                <Col md={8} lg={6}>
                    <Link to="/" ><img src="/Moose-Logo.jpg" className="logo" alt="Logo" onClick={close}/></Link>
                </Col>
            
                <Col md={12} lg={14}>
                    {categories?.map((topCat, i) => 
                        <a
                        key={topCat.categoryId}
                        href="#"
                        onClick={updateCat(topCat)}
                        className={clsx(['nav-item', isActive(topCat) && 'active'])}
                        >
                            {topCat.categoryName}
                        </a>
                    )}
                    <Link className="nav-item" to="/sponsorship"><span onClick={close}>
                        <span>Sponsorship</span>
                    </span></Link>
                    {/* <Link className="nav-item" to="/news"><span onClick={close}>
                        <span>Blog</span>
                    </span></Link> */}
                </Col>
            
                <Col span={4}>
                    <Link className="nav-item gray" to="/dealer-search" title='Dealer Locator'><span onClick={close}>
                        <span className="header-link-label">Dealer Locator</span>
                    </span></Link>
                    <QuickSearch onSearch={close}/>
                </Col>
            </Row>
        </nav>
        {!!activeCat && !!activeCat.categories && <div className="expanded-menu">
            {activeCat.categories.map(subCat => <div className="sub-cat" key={subCat.categoryId}>
                <div className="sub-cat-name" onClick={close}>
                    <Link to={catUrl(subCat.categoryId)}>{subCat.categoryName}</Link>
                </div>
                {!!subCat.categories && <ul>
                    {subCat.categories.map(subSubCat => <li key={subSubCat.categoryId} onClick={close}>
                        <Link
                            to={catUrl(subSubCat.categoryId)}
                            className="sub-cat-link"
                        >
                            {subSubCat.categoryName}
                        </Link>
                    </li>)}
                </ul>}
            </div>)}
        </div>}
    </header>
);