import { Row } from 'antd';
import clsx from 'clsx';
import { IFeedProductFitmentInfo, IFitmentPart } from 'lemans-brandsites-common/libs/ProductFeed/masterFeed.types';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { partUrl } from 'theme/libs/routes';
import { first, prop, unique } from 'ts-functional';
import './ProductFitment.styles.less';
import { IProductFitmentProps } from "./ProductFitment.types";

export const ProductFitment = ({fitments}:IProductFitmentProps) => {
    if(!fitments) {return null;}
    
    const makes = unique(fitments.map(prop("makeName")));

    const [activeMake, setActiveMake] = React.useState(first(makes));
    const updateMake = (make:string) => () => {setActiveMake(make);}

    const getFitmentId = (fitment:IFeedProductFitmentInfo) => `${fitment.modelName}:${fitment.year}`;

    const parts:{[id:string]: IFitmentPart[]} = fitments
        .filter(part => part.makeName === activeMake)
        .reduce(
            (combined, fitment) => ({
                ...combined,
                [getFitmentId(fitment)]: [
                    ...(combined[getFitmentId(fitment)] || []),
                    ...fitment.parts,
                ]
            }),
            {}
        );

    return <div className="productFitments">
        <div className="productFitmentsHeader">
            <span>Fitments</span>
            {makes.map(make => <span key={make} className={clsx([activeMake === make && "active"])} onClick={updateMake(make)}>
                {make}
            </span>)}
        </div>
        <Row className="productFitmentsParts">
            <table className="fitmentPartList">
                <thead>
                    <tr>
                        <th>Year</th>
                        <th>Model</th>
                        <th>Part</th>
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(parts).map((fitmentId, i) => <tr key={fitmentId}>
                        <td>{fitmentId.split(":")[0]}</td>
                        <td>{fitmentId.split(":")[1]}</td>
                        <td>
                            {parts[fitmentId].map(part => <div className="fitmentPart" key={part.partNumber}>
                                <Link to={partUrl(part.partNumber)}>{part.punctuatedPartNumber}</Link>
                            </div>)}
                        </td>
                    </tr>)}
                </tbody>
            </table>
        </Row>
    </div>;
}
