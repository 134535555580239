import { useEffect, useState } from "react";
import { api } from "./api";
import { page } from "./GetPageFromWP";
import { IPageResponseData } from "./GetPageFromWP/page.types";

export function apiHandleSideEffects(
  url: string
): [boolean, boolean, IWordPressResponse | null] {
  const superagent = require("superagent");

  const [data, setData] = useState<IWordPressResponse | null>(null);
  const [loading, setLoading] = useState(false);
  const [fourOhFour, setFourOhFour] = useState(false);

  useEffect(() => {
    setLoading(true);
    superagent
      .get(url)
      .then((r: IWordPressResponse) => {
        if (r && r.ok) {
          setData(r.body);
        } else {
          setFourOhFour(false);
        }
      })
      .catch(() => {
        setFourOhFour(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url]);

  return [fourOhFour, loading, data];
}

export function apiRestHandleSideEffects(
  url: string,
  isSlug?: boolean
): [boolean, boolean, any] {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(false);
  const [fourOhFour, setFourOhFour] = useState(false);

  useEffect(() => {
    setLoading(true);
    api
      .get(url)
      .then((r: IWordPressResponse) => {
        if (r && r.ok) {
          setData(r.body);
        } else {
          setFourOhFour(false);
        }
      })
      .catch(() => {
        setFourOhFour(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url]);

  return [fourOhFour, loading, data];
}

export function pageRestHandleSideEffects(
  url: string
): [boolean, boolean, IPageResponseData | null] {
  const [data, setData] = useState<IPageResponseData | null>(null);
  const [loading, setLoading] = useState(false);
  const [fourOhFour, setFourOhFour] = useState(false);

  useEffect(() => {
    setLoading(true);
    page(url)
      .then((r: IPageResponseData) => {
        r ? setData(r) : setFourOhFour(true);
      })
      .catch(() => {
        setFourOhFour(true);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [url]);

  return [fourOhFour, loading, data];
}

export interface IWordPressResponse extends Response {
  acf?: any;
  body: any;
  items: any;
  title: any;
}
