import { Button, Col, Form, Input, Row } from 'antd';
import { api } from 'lemans-brandsites-common/libs/api';
import { apiConfig } from 'lemans-brandsites-common/libs/config';
import { sanitizeAndFormat } from 'lemans-brandsites-common/libs/formHelpers';
import { isHumanUser, useReCaptcha } from 'lemans-brandsites-common/libs/ReCaptcha';
import * as React from 'react';
import { useState } from 'react';
import { siteKey } from 'theme/libs/config';
import { SpinnerCss } from '../SpinnerCss';
import './ContactForm.styles.less';
import { IContactFormProps, IContactFormState } from "./ContactForm.types";

export const ContactForm = (props: IContactFormProps) => {
    const { isLoading, setIsLoading } = useReCaptcha(siteKey);
    const [state, setState] = useState<IContactFormState>({
        fetching: true,
        message: undefined
    });

    const handleSubmit = async (values: any) => {
        const isValidUser = await validateUser();
        if (!isValidUser) {
            handleSubmissionError();
        } else {
            sanitizeAndFormatValues(values);
            submitToCorrespondence(values);
        }
    };

    const sanitizeAndFormatValues = (values: any) => {
        for (const key in values) {
            if (values.hasOwnProperty(key)) {
                values[key] = sanitizeAndFormat(key, values[key]);
            }
        }
    }

    const validateUser = async () => {
        let output;

        try {
            setIsLoading(true);
            output = await isHumanUser(siteKey, 'contactUs', apiConfig);
        }
        catch {
            output = false;
        }
        finally {
            setIsLoading(false);
        }

        return output;
    }

    const submitToCorrespondence = async (values: any) => {
        const url = `lemans/rest/domain/${props.domain}/form/${props.formKey}/submit`;

        setIsLoading(true);
        try {
            const response = await api.post(url, values, 'form');

            if (response.statusCode === 200) {
                setState({
                    ...state,
                    message: `<h3>Your Message Has Been Sent</h3>
                    <p>Thank you for contacting us. We have received your submission and will respond as soon as possible.</p>`
                });
            } else {
                handleSubmissionError();
            }
        }
        catch (err) {
            handleSubmissionError();
        }
        finally {
            setIsLoading(false);
        }
    }

    const handleSubmissionError = () => setState({
        ...state, message:
            `<h3>Server Error</h3>
            <p>We're sorry, there was a server error. Please try again later.</p>`
    });

    return (
        <>
            {isLoading &&
                <SpinnerCss />
            }
            {!isLoading && state.message &&
                <div dangerouslySetInnerHTML={{ __html: state.message }} />
            }
            {!isLoading && !state.message &&
                <div className="ContactForm">
                    <Form onFinish={handleSubmit} layout="vertical">
                        <Row>
                            <Col span={24}>
                                <Form.Item label='Name' name='form_1_name' rules={[{
                                    message: 'Required field',
                                    required: true,
                                    type: 'string',
                                }]}
                                    validateTrigger={['onChange', 'onBlur']}
                                >
                                    <Input id='name' maxLength={30} allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item label='Email' name='form_2_emailAddress' rules={[{
                                    message: 'Not a valid email address',
                                    required: true,
                                    type: 'email',
                                    // warning: true,
                                }]}
                                    validateTrigger={['onChange', 'onBlur']}
                                >
                                    <Input type={'email'} id='email' allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item label='Message' name='form_3_comments'>
                                    <Input.TextArea rows={8} id='comments' allowClear />
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col>
                                <Form.Item>
                                    <Button className="styled-button-wrapper--button" htmlType={'submit'}>Submit</Button>
                                </Form.Item>
                            </Col>
                        </Row>
                    </Form>
                </div>
            }
        </>
    );
}